import ICON_POI from "assets/icon/icon_poi.svg";
import axios from 'axios';
import { FEATURE_SPLIT_LENGTH, FEATURE_TYPES, LAYER, LAYER_PROPS } from 'constants/Constants';
import { OlDefaultView } from 'data/Ol';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { FeatureLike } from 'ol/Feature';
import GeoJSON, { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { Geometry, MultiPolygon, Point } from 'ol/geom';
import Polygon from 'ol/geom/Polygon';
import { SketchCoordType } from 'ol/interaction/Draw.js';
import Layer from 'ol/layer/Layer';
import VectorLayer from "ol/layer/Vector";
import { getRenderPixel } from 'ol/render';
import RenderEvent from 'ol/render/Event';
import { XYZ } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import { useCallback, useRef } from 'react';
import { CustomLayerInfo, customLayerStore } from 'stores/CustomLayer';
import { mapStore } from 'stores/Map';
import { FeatureTypes } from 'types/TFeature';
import { TRequestContent } from 'types/TRequest';
import { FeatureProperties, TChangeDetectFeature, TFeature, TResult } from 'types/TResult';
import { convert4326To3857, onUpdateBBox } from 'utils/OlUtil';
import { LAYER_FACTORY, TLayerFactory, WebGLLayerFactory } from 'utils/WebGLLayerFactory';

export const useOpenLayers = () => {
    const { getMap, map, roadSource, buildingSource, changeSource, change2Source, objectSource, customSource, customLayer, change2Layer, roadLayer, buildingLayer, changeLayer, objectLayer, baseImageLayer, baseImageSource, compareImageLayer, compareImageSource, getVectorSource } = mapStore();
    const { setCustomLayerInfos } = customLayerStore();
    const swipeRef = useRef<number>(50);

    const sourceMapper = {
        object: objectSource,
        change: changeSource,
        change2: change2Source,
        road: roadSource,
        building: buildingSource,
        custom: customSource,
    };

    const layerMapper = {
        object: objectLayer,
        change: changeLayer,
        change2: change2Layer,
        road: roadLayer,
        building: buildingLayer,
        custom: customLayer
    };

    //layer name별 Visible 세팅
    const updateLayerVisibleByName = (layerName: string, is: boolean) => {
        const layers = findLayers(LAYER_PROPS.LAYER_NAME, layerName);
        layers?.forEach((layer) => {
            layer.setVisible(is);
        })   
    }

    //layer 전체 Opacity
    const updateLayerOpacity = (opacity: number) => {
        const layers = findResultLayer();
        layers?.forEach((layer) => {
            layer.setOpacity(opacity);
        });
    };

    //layer 전체 Visible
    const updateLayerVisible = (is: boolean, isCustom: boolean) => {
        const layers = isCustom ? findCustomLayer() : findResultLayer();
        layers?.forEach((layer) => {
            layer.setVisible(is);
        });
    };

    //타일 이미지 Opacity
    const updateBaseImageOpacity = (opacity: number) => {
        baseImageLayer?.setOpacity(opacity);
        compareImageLayer?.hasListener('prerender') && compareImageLayer.setOpacity(opacity);
    };

    //타일 이미지 Visible
    const updateBaseImageVisible = (is: boolean) => {
        baseImageLayer?.setVisible(is);
        compareImageLayer?.hasListener('prerender') && compareImageLayer.setVisible(is);
    };

    const drawRectangle = (coordinates: SketchCoordType) => {
        const start = coordinates[0] as number[];
        const end = coordinates[1] as number[];
        const minX = Math.min(start[0], end[0]);
        const maxX = Math.max(start[0], end[0]);
        const minY = Math.min(start[1], end[1]);
        const maxY = Math.max(start[1], end[1]);

        return new Polygon([
            [
                [minX, minY],
                [maxX, minY],
                [maxX, maxY],
                [minX, maxY],
                [minX, minY],
            ],
        ]);
    };

    const drawBBox = (request: TRequestContent, isFit: boolean = true) => {
        clearAll();
        const first = request.requestImageDataList[0];
        if (!first) return;
        const geometry = drawRectangle([convert4326To3857([first.swLng, first.swLat]), convert4326To3857([first.neLng, first.neLat])]);
        const feature = new Feature({ geometry, name: request.name });
        feature.setStyle(onUpdateBBox);
        getVectorSource()?.addFeature(feature);        
        isFit && getMap()?.getView().fit(geometry, { padding: [170, 50, 150, 500] });
    };

    const createChangeDetectFeatures = (features: Array<GeoJSONFeatureCollection>) => {
        return features.map((feat: TChangeDetectFeature) => {
            const geo = new MultiPolygon(
                feat.geometry.coordinates.map((coords) => {
                    return coords.map((multiPoly) => {
                        return multiPoly.map((vec) => {
                            return convert4326To3857(vec);
                        });
                    });
                })
            );

            const feature = new Feature(geo);
            setupPropertiesAtFeature(feature, feat.properties);
            return feature;
        });
    };

    const createFeatures = (features: Array<GeoJSONFeatureCollection>) => {
        return features.map((feat: TFeature) => {
            const geo = new Polygon(
                feat.geometry.coordinates.map((coords) => {
                    return coords.map((vec) => {
                        return convert4326To3857(vec);
                    });
                })
            );

            const feature = new Feature(geo);
            setupPropertiesAtFeature(feature, feat.properties);
            return feature;
        });
    };

    const setupPropertiesAtFeature = (feature: Feature, properties: FeatureProperties) => {
        for (const [key, value] of Object.entries(properties)) {
            feature.set(key, value);
        }
    };

    let addedLayerList: Array<Layer> = [];
    let addedCustomLayerList: Array<Layer> = [];

    const drawResults = async (detail: TResult, type: TLayerFactory) => {
        clearFeatures();
        const featureCollections = detail.featureCollections;
        if (detail.customLayerList.length > 0) {
            await addCustomFeaturesLayer(detail.customLayerList);
        }
        for (const index in featureCollections) {
            const features = new GeoJSON().readFeatures(featureCollections[index], { featureProjection: 'EPSG:3857' });
            if (getFeatureType(features[0]) === FEATURE_TYPES.POINT) {
                addPointFeaturesLayer(features, type, Number(index));
            } else {
                addSpliteFeaturesLayer(features, type, Number(index), featureCollections[index].class);
            }
        }
    }

    const drawBaseImage = (url: string) => {
        baseImageSource && baseImageSource.setUrl(url);
    };

    const drawCompareImage = (url: string) => {
        compareImageSource && compareImageSource.setUrl(url);
    };

    const addCompareLayerEvent = () => {
        compareImageLayer?.on('prerender', onPrerender);
        compareImageLayer?.on('postrender', onPostRender);
    };

    const addPointFeaturesLayer = (features: Feature<Geometry>[], type: TLayerFactory, index?: number) => {
        const layer = createNewGLLayer(LAYER_FACTORY.POINT);
        if (!layer) return;
        index !== undefined && layer.set(LAYER_PROPS.LAYER_NAME, makeLayerName(type, index));
        layer.set(LAYER_PROPS.LAYER_TYPE, LAYER.RESULT_LAYER);
        (layer.getSource() as VectorSource).addFeatures(features);
        map?.addLayer(layer);
        addedLayerList.push(layer);
    }

    const addSpliteFeaturesLayer = (features: Feature<Geometry>[], type: TLayerFactory, index?: number, className?: string) => {
        let length = features.length;
        while (length > 0) {
            if (type === LAYER_FACTORY.CUSTOM && (index === undefined || index === null)) return;
            const layer = createNewGLLayer(type, index, className);
            if (!layer) return;
            index !== undefined && layer.set(LAYER_PROPS.LAYER_NAME, makeLayerName(type, index));
            layer.set(LAYER_PROPS.LAYER_TYPE, type === 'custom' ? LAYER.CUSTOM_LAYER : LAYER.RESULT_LAYER);
            const split = features.splice(0, FEATURE_SPLIT_LENGTH);
            (layer.getSource() as VectorSource).addFeatures(split);
            map?.addLayer(layer);
            type === LAYER_FACTORY.CUSTOM ? addedCustomLayerList.push(layer) : addedLayerList.push(layer);
            length = features.length;
        }
    };

    const addCustomFeaturesLayer = async (geojsonUrls: string[]) => {
        let customLayers: CustomLayerInfo[] = [];

        for(const url of geojsonUrls) {
            const result = await axios.get(url);
            if (result) {
                const layerInfo: CustomLayerInfo = {
                    name: result.data.name,
                    featureCount: result.data.features.length,
                };
                customLayers.push(layerInfo);
                const features = new GeoJSON().readFeatures(result.data, {featureProjection: 'EPSG:3857'});
                addSpliteFeaturesLayer(features, LAYER_FACTORY.CUSTOM, geojsonUrls.indexOf(url));
            }
        }
        setCustomLayerInfos(customLayers);
    }

    const onPrerender = useCallback((event: RenderEvent) => {
        if (!event) return;
        const ctx = event.context;
        if (!(ctx instanceof CanvasRenderingContext2D)) return;
        const mapSize = map?.getSize();
        if (typeof mapSize === 'undefined') return;

        const width = mapSize[0] * (swipeRef.current / 100);
        const tl = getRenderPixel(event, [width, 0]);
        const tr = getRenderPixel(event, [mapSize[0], 0]);
        const bl = getRenderPixel(event, [width, mapSize[1]]);
        const br = getRenderPixel(event, mapSize);

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(tl[0], tl[1]);
        ctx.lineTo(bl[0], bl[1]);
        ctx.lineTo(br[0], br[1]);
        ctx.lineTo(tr[0], tr[1]);
        ctx.closePath();
        ctx.clip();
    }, [map]);

    const onPostRender = useCallback((event: RenderEvent) => {
        if (!event) return;
        const ctx = event.context;
        if (!(ctx instanceof CanvasRenderingContext2D)) return;
        ctx.restore();
    }, [map]);

    const createNewGLLayer = (type: TLayerFactory, index?: number, className?: string) => {
        const source = new VectorSource({ wrapX: false });
        const layer = new WebGLLayerFactory().getGLLayerByType(type, { source }, index, className);
        return layer;
    };

    const findLayer = (propName: string, value: any) => {
        const layers = map?.getAllLayers();
        return layers?.find((layer) => layer.get(propName) === value);
    }

    const findLayers = (propName: string, value: any) => {
        const layers = map?.getAllLayers();
        return layers?.filter((layer => layer.get(propName) === value));
    }

    const makeLayerName = (type: TLayerFactory, name: number | string) => {
        return `${type}_LAYER_${name}`;
    }

    const clearAll = () => {
        getVectorSource()?.clear();
        clearFeatures();
        clearBaseImage();
        clearCompareImage();
        clearOverlays()
    };

    const clearFeatures = () => {
        roadSource && roadSource.clear();
        buildingSource && buildingSource.clear();
        changeSource && changeSource.clear();
        objectSource && objectSource.clear();
        const resultLayers = findResultLayer();
        const customLayers = findCustomLayer();
        resultLayers && clearLayers(resultLayers);
        customLayers && clearLayers(customLayers);
        // findResultLayer()?.forEach((layer) => {
        //     (layer.getSource() as VectorSource).clear();
        //     layer.dispose();
        //     map?.removeLayer(layer);
        // });
        // findCustomLayer()?.forEach((layer) => {
        //     layer.dispose();
        //     map?.removeLayer(layer);
        // })
        updateLayerOpacity(100);
        updateLayerVisible(true, false);
    };

    const clearBaseImage = () => {
        if (!(baseImageSource instanceof XYZ)) return;
        baseImageSource.clear();
        baseImageSource.setUrl('');
        updateBaseImageOpacity(100);
        updateBaseImageVisible(true);
    };

    const removeCompareLayerEvent = () => {
        compareImageLayer?.hasListener('prerender') && compareImageLayer.un('prerender', onPrerender);
        compareImageLayer?.hasListener('postrender') && compareImageLayer.un('postrender', onPostRender);
    }

    const clearCompareImage = () => {
        if (!(compareImageSource instanceof XYZ)) return;
        removeCompareLayerEvent();
        compareImageSource.clear();
        compareImageSource.setUrl('');
        compareImageLayer?.setOpacity(100);
        compareImageLayer?.setVisible(true);
    };

    const clearOverlays = () => {
        map?.getOverlays().clear();
    };

    const rerender = () => {
        map?.render();
    };

    const initSwipe = () => {
        swipeRef.current && (swipeRef.current = 50);
    }

    const initMap = () => {
        map?.getView().setCenter(OlDefaultView.center)
        map?.getView().setZoom(OlDefaultView.zoom);
    }

    const findResultLayer = () => {
        return findLayers(LAYER_PROPS.LAYER_TYPE, LAYER.RESULT_LAYER);
    }

    const findCustomLayer = () => {
        return findLayers(LAYER_PROPS.LAYER_TYPE, LAYER.CUSTOM_LAYER);
    }

    const getFeatureType = (feature: FeatureLike) => {
        const values = feature.getProperties();
        return values.geometry.getType() as FeatureTypes;
    }

    const setViewAnimate = (coordinate: Coordinate) => {
        const duration = 2000;
        const view = map?.getView();
        const zoom = view?.getZoom();
        if (!view || !zoom) return;
        view.animate({ center: coordinate, duration: duration });
        view.animate(
        { zoom: zoom - 0.5, duration: duration / 2, },
        { zoom: zoom > 18 ? zoom : 18, duration: duration / 2, }
        );
    }

    const clearLayers = (layers: Layer[]) => {
        layers.forEach((layer) => {
            (layer.getSource() as VectorSource).clear();
            layer.dispose();
            map?.removeLayer(layer);
        })
    }

    const setPoiIcon = (coordinate: Coordinate) => {
        clearOverlays();
        const iconFeature = new Feature({ geometry: new Point(coordinate) });
        const iconStyle = new Style({
            image: new Icon({
                anchor: [0.5, 50],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: ICON_POI,
            })
        })
        iconFeature.setStyle(iconStyle);
        
        const vectorSource = new VectorSource({ features: [iconFeature], });
        const vectorLayer = new VectorLayer({
            source: vectorSource,
            zIndex: 12,
        });

        vectorLayer.set(LAYER_PROPS.LAYER_TYPE, LAYER.POI_LAYER);
        map?.addLayer(vectorLayer);
    }

    const clearPoiLayer = () => {
        const layer = findLayer(LAYER_PROPS.LAYER_TYPE, LAYER.POI_LAYER);
        layer && map?.removeLayer(layer);
    }

    return {
        map,
        swipeRef,
        drawBBox,
        drawResults,
        drawBaseImage,
        drawCompareImage,
        updateLayerVisibleByName,
        updateLayerOpacity,
        updateLayerVisible,
        updateBaseImageOpacity,
        updateBaseImageVisible,
        findLayer,
        findLayers,
        clearAll,
        clearFeatures,
        clearBaseImage,
        clearCompareImage,
        clearOverlays,
        rerender,
        initSwipe,
        initMap,
        addCompareLayerEvent,
        removeCompareLayerEvent,
        addSpliteFeaturesLayer,
        makeLayerName,
        findResultLayer,
        findCustomLayer,
        getFeatureType,
        setViewAnimate,
        clearPoiLayer,
        setPoiIcon
    };
};
