import { getMemberById } from 'api/Member';
import { MemberStore } from 'stores/Member';

export const useMember = () => {
    const { id } = MemberStore();

    const getMember = async () => {
        return await getMemberById(id);
    };

    return {
        getMember,
    };
};
