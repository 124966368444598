import styles from './DetectionResultContainer.module.scss';
import { DetectionResultContainerProps } from './DetectionResultContainer.type';

const DetectionResultContainer = ({ total, children }: DetectionResultContainerProps) => {
    return (
        <div className={styles['container']}>
            <div className={styles['header']}>
                <span>Total</span>
                <span className={styles['total']}>{total}</span>
            </div>
            {children}
        </div>
    );
};

export default DetectionResultContainer;
