import { MAIN_MENU, TMainMenu, TUserMenu, USER_MENU, USER_MENU_ROLE } from 'constants/Constants';
import { TActive } from 'types/TActive';
import { TMenuRoles } from 'types/TMenuRoles';
import { TName } from 'types/TName';
import { TType } from 'types/TType';

// export const MenuList:Array<TName & TActive & TType<TMainMenu>> = Array.from(Object.values(MAIN_MENU)).map((value) => {
//     return { type: value, name: value.toLowerCase(), active: false };
// });

export const MenuList: Array<TName & TActive & TType<TMainMenu>> = [{ type: MAIN_MENU.RESULT, name: MAIN_MENU.RESULT, active: true }];

export const UserMenuList: Array<TMenuRoles & TType<TUserMenu>> = Array.from(Object.values(USER_MENU)).map((value) => {
    return { type: value, roles: USER_MENU_ROLE[value] };
});
