import styles from "./Button.module.scss";
import classNames from "classnames";
import { BUTTON_COLOR_TYPE, ButtonProps } from "./Button.type";
import { useMemo } from "react";

const Button = ({type,width,height,colorType = BUTTON_COLOR_TYPE.blue, children, onClick}: ButtonProps) => {
    const style = useMemo(() => ({
        width: `${width}px`, height: `${height}px`
    }), [width, height]);

    return (
        <button
            type={type}
            className={classNames(styles["button"], styles[colorType])}
            onClick={onClick}
            style={style}
        >
            {children}
        </button>
    );
};

export default Button;
