import classNames from 'classnames';
import CheckBox from 'components/common/checkBox/CheckBox';
import { useState } from 'react';
import FoldButton from '../../common/foldButton/FoldButton';
import OpacityControl from '../../common/opacityControl/OpacityControl';
import styles from './ResultControl.module.scss';
import { ResultControlProps } from './ResultControl.type';

const ResultControl = ({ title, checked, opacity, isOpacity = true, isFolder = true, opacityDisabled, onChangeCheck, onChangeOpacity, children }: ResultControlProps) => {
    const [isOpen, setOpen] = useState(true);

    return (
        <div className={styles['container']}>
            <div className={styles['title-area']}>
                <div className={styles['title']}>
                    <CheckBox label={title} checked={checked} onChange={(e) => onChangeCheck(e.target.checked)} />
                </div>
                {isFolder && <FoldButton open={isOpen} onClick={() => setOpen(!isOpen)} />}
            </div>
            {isOpacity && opacity !== undefined && onChangeOpacity && <div className={classNames({ [styles['folded']]: !isOpen })}>
                <OpacityControl opacity={opacity} onChange={onChangeOpacity} disabled={opacityDisabled} />
            </div>}
            {isOpen && (children)}
        </div>
    );
};

export default ResultControl;
