import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { makeRandomId } from 'utils/Utils';
import styles from './CheckBox.module.scss';
import { CheckBoxProps } from './CheckBox.type';

const CheckBox = ({ checked, label, disabled, labelWidth, width, height, onChange, circleColor }: CheckBoxProps) => {
    const [checkedValue, setCheckedValue] = useState(checked);
    const [id] = useState(makeRandomId());

    useEffect(() => {
        setCheckedValue(checked);
    }, [checked]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e);
        setCheckedValue(!checkedValue);
    };

    return (
        <div className={classNames(styles['checkbox__container'])} style={{ width: width, height: height }}>
            <input
                onChange={(e) => handleChange(e)}
                disabled={disabled}
                value={label || ''}
                id={id}
                type="checkbox"
                className={styles['-checkbox']}
                checked={checkedValue}
            />
            {label && (
                <label htmlFor={id} className={classNames(styles['-label'])} style={{ width: labelWidth }}>
                    {circleColor && <div className={classNames(styles['-circle'])} style={{ backgroundColor: circleColor }}></div>}
                    {label}
                </label>
            )}
        </div>
    );
};

export default CheckBox;
