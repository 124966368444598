import Header from 'components/header/Header';
import Loading from 'components/loading/Loading';
import Dim from 'components/popup/dim/Dim';
import { Language } from 'constants/Constants';
import { useAuthentication } from 'hooks/feature/authentication/useAuthentication';
import Main from 'pages/main/Main';
import { useEffect, useState } from 'react';
import { LoadingStore } from 'stores/Loading';
import { redirectAdmin } from 'utils/AuthenticationUtil';
import { getPath, setLanguage } from 'utils/Utils';
import './App.css';

function App() {
    const [initialized, setInitialized] = useState(false);
    const { setupToken } = useAuthentication();
    const { isLoading } = LoadingStore();

    useEffect(() => {
        handleToken();
        setProjectLanguage();
    }, []);

    const handleToken = async () => {
        (await setupToken()) ? setInitialized(true) : redirectAdmin();
    };

    //[MEMO] LUCY: 임시로 KAMCO만 한글버전으로 나오게 설정함.. 2024.11.7
    const setProjectLanguage = () => {
        if (getPath() === "kamco") {
            setLanguage(Language.KO);
        }
    }

    return initialized ? (
        <div className="App">
            <Header></Header>
            <Main />
            {isLoading && (
                <Dim>
                    <Loading></Loading>
                </Dim>
            )}
        </div>
    ) : (
        <></>
    );
}

export default App;
