import styles from './Dim.module.scss';
import { DimProps } from './Dim.type';

const Dim = ({ children }: DimProps) => {
    return (
        <div className={styles['dim__container']} onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
            <div className={styles['dim__wrap']}>{children}</div>
        </div>
    );
};
export default Dim;
