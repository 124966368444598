import classNames from "classnames";
import TaskBadgeBox from "components/badge/taskBadgeBox/TaskBadgeBox";
import StatusButton from "components/statusButton/StatusButton";
import { TRequestContent } from "types/TRequest";
import styles from "./ResultCardItem.module.scss";

type ResultCardProps = {
    item: TRequestContent
    onSelect: (task: TRequestContent) => void;
    onClickDetail: () => void;
    selected: TRequestContent | null
}

const ResultCardItem = ({ item, onSelect, onClickDetail, selected }: ResultCardProps) => {

    const onClickShowDetail = (event: React.MouseEvent) => {
        event.stopPropagation();
        onSelect(item);
        onClickDetail();
    }

    const onClickSelected = (event: React.MouseEvent): void => {
        event.stopPropagation();
        onSelect(item);
    }


    return <div className={classNames([styles.container, selected && selected.id === item.id && styles.selected])} onClick={onClickSelected}>
        <div className={styles['info-box']}>
            <span className={styles.name}>{item.name}</span>
            <span className={styles.period}>{item.createdDate}</span>
            <TaskBadgeBox task={item.aiCore.task} coreName={item.aiCore.coreName} ></TaskBadgeBox>
        </div>
        <StatusButton status={item.status} onClick={onClickShowDetail}></StatusButton>
    </div>
};

export default ResultCardItem;
