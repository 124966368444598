import classNames from 'classnames';
import DropdownBox from 'components/common/dropdown/DropdownBox';
import ResultCardItem from 'components/resultCardItem/ResultCardItem';
import { MAIN_MENU, NONE } from 'constants/Constants';
import { useRequest } from 'hooks/feature/useRequest';
import { useCallback, useEffect, useState } from 'react';
import { PanelStateStore } from 'stores/PanelState';
import { ProjectListStore } from 'stores/ProjectList';
import { SelectedProjectStore } from 'stores/SelectedProject';
import { TRequestContent } from 'types/TRequest';
import { debounce } from 'utils/Utils';
import { useFilter } from '../../hooks/feature/useFilter';
import Detail from './detail/Detail';
import List from './list/List';
import styles from './Panel.module.scss';
import SearchFilterBox from './searchFilter/SearchFilterBox';

type PanelProps = { menu: string; requestList: Array<TRequestContent> };
const Panel = ({ menu, requestList }: PanelProps) => {
    const { isOpen, setState } = PanelStateStore();
    const { projectList } = ProjectListStore();
    const { isInit, getProjectId } = SelectedProjectStore();
    const { selectedRequest, isDetail, getNextRequest, onSelectRequest, onClickDetail, setSelected } = useRequest();
    const { filterList, search } = useFilter();
    const [hasMore, setHasMore] = useState<boolean>(true);
    useEffect(() => {
        setState(isPanelOpen(menu));
    }, [menu]);

    useEffect(() => {
        search();
    }, [filterList]);

    const onScroll = async () => {
        const pID = getProjectId();
        if (pID) {
            const has = await getNextRequest(pID);
            setHasMore(has);
        }
    };

    const debouncedOnScroll = debounce<typeof onScroll>(onScroll, 500);

    const onInitDetail = () => {
        onClickDetail(false);
        setSelected(null);
    };

    const isPanelOpen = (state: string) => {
        return state !== NONE;
    };

    const createListChildren = () => {
        return requestList.length > 0
            ? requestList.map((item, idx) => {
                return (
                    <ResultCardItem
                        item={item}
                        selected={selectedRequest}
                        onClickDetail={onClickDetail}
                        onSelect={onSelectRequest}
                        key={`${item.id}-${idx}`}
                    ></ResultCardItem>
                );
            })
            : [];
    };

    const setTaskListPanel = () => {
        return (
            <>
                <DropdownBox list={projectList}></DropdownBox>
                <SearchFilterBox></SearchFilterBox>
                <List children={createListChildren()} onScroll={debouncedOnScroll} hasMore={hasMore} />
            </>
        );
    };

    const initRequestPanel = () => {
        return <></>;
    };

    const initDetailPanel = useCallback(() => {
        return isDetail && selectedRequest && <Detail requestId={selectedRequest.id} onBack={onInitDetail} />;
    }, [isDetail]);

    return (
        <div className={classNames([styles.panel, isOpen ? styles.open : ''])}>
            {!isDetail && menu === MAIN_MENU.RESULT ? setTaskListPanel() : initRequestPanel()}
            {initDetailPanel()}
        </div>
    );
};

export default Panel;
