import TaskBadgeBox from 'components/badge/taskBadgeBox/TaskBadgeBox';
import Button from 'components/common/button/Button';
import { TASK } from 'constants/Constants';
import { useResult } from 'hooks/feature/useResult';
import { useOpenLayers } from 'hooks/ol/useOpenLayers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { customLayerStore } from 'stores/CustomLayer';
import { LoadingStore } from 'stores/Loading';
import { TResult } from 'types/TResult';
import { getLayerByType } from 'utils/OlUtil';
import { LAYER_FACTORY } from 'utils/WebGLLayerFactory';
import styles from './Detail.module.scss';
import { DetailProps } from './Detail.type';
import DetailPanel from './panel/DetailPanel';

const Detail = ({ requestId, onBack }: DetailProps) => {
    const { t } = useTranslation();
    const { getResult, downloadGeoJSON } = useResult();
    const [result, setResult] = useState<TResult | null>(null);
    const { setLoading } = LoadingStore();
    const { customLayerInfos } = customLayerStore();
    const {
        clearAll,
        drawResults,
        drawBaseImage,
        updateLayerVisibleByName,
        updateLayerOpacity,
        updateLayerVisible,
        updateBaseImageOpacity,
        updateBaseImageVisible,
        drawCompareImage,
        addCompareLayerEvent,
        rerender,
        makeLayerName,
        swipeRef,
        initSwipe,
        findResultLayer,
        findCustomLayer
    } = useOpenLayers();
    useEffect(() => {
        handleFetchResult();
        addCompareLayerEvent();
    }, [requestId]);

    useEffect(() => {
        setLoading(true);
    }, []);

    const handleFetchResult = async () => {
        const result = await getResult(requestId);
        setResult(result);
        const type = getLayerByType(result.request.aiCore.task);
        await drawResults(result, type);
        drawBaseImage(result.request.requestImageDataList[0].imageTileUrl);
        setLoading(false);
    };

    const handleDownloadGeoJSON = async () => {
        setLoading(true);
        await downloadGeoJSON(result!.request.id);
        setLoading(false);
    };

    const getLayerType = () => {
        switch (TASK[result!.request.aiCore.task]) {
            case TASK.CHANGE_DETECTION:
                return LAYER_FACTORY.CHANGE;
            case TASK.OBJECT_DETECTION:
                return LAYER_FACTORY.OBJECT;
            case TASK.SEGMENTATION:
                return LAYER_FACTORY.SEGMENTATION;
            default:
                return LAYER_FACTORY.CUSTOM;
        }
    }

    const initDetailComponent = () => {
        return TASK[result!.request.aiCore.task] === TASK.CHANGE_DETECTION ? (
            <DetailPanel
                swipeRef={swipeRef}
                rerender={rerender}
                drawCompareImage={drawCompareImage}
                result={result!}
                updateResultOpacity={updateLayerOpacity}
                updateResultVisible={updateLayerVisible}
                updateImageOpacity={updateBaseImageOpacity}
                updateImageVisible={updateBaseImageVisible}
                updateResultVisibleByName={updateLayerVisibleByName}
                customLayerInfos={customLayerInfos}
                makeLayerNameByType={makeLayerName}
                getResultLayers={findResultLayer}
                getCustomLayers={findCustomLayer}
                isChangeDetection={true}
                layerType={getLayerType()}
            />
        ) : (
            <DetailPanel
                result={result!}
                updateResultOpacity={updateLayerOpacity}
                updateResultVisible={updateLayerVisible}
                updateImageOpacity={updateBaseImageOpacity}
                updateImageVisible={updateBaseImageVisible}
                updateResultVisibleByName={updateLayerVisibleByName}
                customLayerInfos={customLayerInfos}
                makeLayerNameByType={makeLayerName}
                getResultLayers={findResultLayer}
                getCustomLayers={findCustomLayer}
                layerType={getLayerType()}
            />
        );
    };

    const onInitLayer = () => {
        clearAll();
        initSwipe();
        onBack();
    };

    return (
        <>
            {result && (
                <div className={styles['container']}>
                    <div className={styles['titlebar']} onClick={onInitLayer}>
                        <span className={styles['icon']}></span>
                        <span className={styles['title']}>{t('panel.detail.title')}</span>
                    </div>
                    <div className={styles['request-info']}>
                        <div className={styles['title']}>{result.request.name}</div>
                        <div className={styles['date']}>{result.request.createdDate}</div>
                        <div>
                            <TaskBadgeBox task={result.request.aiCore.task} coreName={result.request.aiCore.coreName} />
                        </div>
                    </div>
                    <div className={styles['result-wrap']}>
                        <div className={styles['content-wrap']}>{initDetailComponent()}</div>
                        <div className={styles['footer-wrap']}>
                            <div className={styles['right']}>
                                <Button colorType="blue" width={101} height={36} onClick={handleDownloadGeoJSON}>
                                    {t('panel.detail.download')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Detail;
