import api from 'api/Instance';
import { Tokens, UserRoles } from 'types/authentication/token';

export const redirectAdmin = () => {
    window.location.href = process.env.REACT_APP_EE_ADMIN_URL!;
};

export const setTokens = (tokens: Tokens) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`;
};


export const extractUserRoles = () : UserRoles[] => {
    const accessToken = api.defaults.headers.common['Authorization'];
    if (typeof accessToken !== 'string') return [];
    return decodeAccessToken(accessToken).resource_access["dev-client"].roles;
}

function decodeAccessToken(token: string) {
    return JSON.parse(atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')));
}