import axios from 'axios';
import { Authentication } from 'types/api/authentication';
import { AuthenticationResult } from 'types/authentication/token';
import { makeApiUri } from 'utils/Utils';
import api from './Instance';
import { AUTHENTICATION } from 'constants/Api';
import { HEADER_MEMBER_ID } from 'constants/Constants';

export async function postRefreshToken() {
    let authenticationResult: AuthenticationResult = {
        memberId: -1,
        tokens: {
            accessToken: '',
            refreshToken: '',
        },
    };

    try {
        const { headers, data } = await axios.post<Authentication>(makeApiUri(AUTHENTICATION.refresh_token), {}, { withCredentials: true });
        authenticationResult = {
            memberId: headers[HEADER_MEMBER_ID],
            tokens: { accessToken: data.access_token, refreshToken: data.refresh_token },
        };
    } catch (e) { }

    return authenticationResult;
}

export async function postLogout() {
    let result = true;

    try {
        await api.post(makeApiUri(AUTHENTICATION.logout));
    } catch (e) {
        result = false;
    }

    return result;
}
