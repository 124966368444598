import React from "react";
import { TSTatusButtonProps } from "./StatusButtonType";
import styles from "./StatusButton.module.scss";
import classNames from "classnames";
import { STATUS, STATUS_STYLE_NAME, TStatus } from "constants/Constants";
const StatusButton = ({ status, onClick }: TSTatusButtonProps) => {

    const isCompleted = (state: TStatus) => {
        return STATUS[state] === STATUS.COMPLETED;
    }
    const getButtonTextBy = (state: TStatus) => {
        return isCompleted(state) ? 'Result' : STATUS[state];
    }

    const onClickBtn = (event: React.MouseEvent) => {
        isCompleted(status) && onClick(event);
    }

    return <label className={classNames([styles.container, styles[STATUS_STYLE_NAME[status]]])} onClick={onClickBtn}>
        {isCompleted(status) && <i className={classNames([styles.ico, styles.result])}></i>}
        {getButtonTextBy(status)}
    </label>;
}

export default StatusButton;