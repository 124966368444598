import React, { useState } from 'react';
import { TClick, TMenuItem, TState, TUserMenuItem } from './MenuItem.type';
import styles from './MenuItem.module.scss';
import { MENU_STATE, NONE } from 'constants/Constants';
import { useEffect } from 'react';
import { CurrentMenuStore } from 'stores/CurrentMenu';
import { extractUserRoles } from 'utils/AuthenticationUtil';
import { UserRoles } from 'types/authentication/token';
import { PanelStateStore } from 'stores/PanelState';
const MenuItem = <T extends (TMenuItem | TUserMenuItem) & TClick>(prop: T & TState): JSX.Element => {
    const [active, setState] = useState(false);
    const { isOpen } = PanelStateStore();
    const { menu } = CurrentMenuStore();
    useEffect(() => {
        updateActiveState(menu);
    }, [menu]);

    const updateActiveState = (state: string) => {
        setState(state !== NONE);
    };

    const onClick = (event: React.MouseEvent) => {
        prop.onClick<typeof prop.type>(event, prop.type);
    };

    const MainMenuItem = (item: TMenuItem) => {
        return (
            <div className={`${styles.menu} ${(active && isOpen) ? styles.active : ''}`} key={item.type} onClick={onClick}>
                <span className={`${styles.ico} ${styles[item.type.toLowerCase()]}`}></span>
                <span className="title">{item.name}</span>
            </div>
        );
    };

    const checkRole = (menuRoles: UserRoles[]) => {
        let isPossibleMenu = false;
        if (menuRoles.length === 0) return true;
        const userRoles = extractUserRoles();
        menuRoles.some(role => {
            const included = userRoles.includes(role);
            if (included) {
                isPossibleMenu = true;
                return true;
            }
        })

        return isPossibleMenu;
    };

    const UserMenuItem = ({ type, roles }: TUserMenuItem) => {
        return checkRole(roles) ? (
            <div className={`${styles.menu} ${styles[type]}`} key={type} onClick={onClick}>
                <span className={`${styles.ico} ${styles[type]}`}></span>
            </div>
        ) : (
            <></>
        );
    };

    return <>{prop.state === MENU_STATE.MAIN ? MainMenuItem(prop as TMenuItem) : UserMenuItem(prop as TUserMenuItem)}</>;
};

export default MenuItem;
