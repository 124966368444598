import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { integerToDecimal } from 'utils/Utils';
import { LAYER_FACTORY } from 'utils/WebGLLayerFactory';
import Information from '../changeDetection/information/Information';
import Swipe from '../changeDetection/swipe/Swipe';
import { DetailChildProps } from '../Detail.type';
import CustomResultContainer from '../task/control/custom/container/CustomResultContainer';
import ResultControlItem from '../task/control/item/ResultControlItem';
import ResultControl from '../task/control/ResultControl';
import DetectionResultContainer from '../task/detectionResult/container/DetectionResultContainer';

const DetailPanel = ({ isChangeDetection, swipeRef, result, customLayerInfos, layerType, getResultLayers, getCustomLayers, makeLayerNameByType, updateResultVisibleByName, rerender, drawCompareImage, updateResultVisible, updateImageVisible, updateResultOpacity, updateImageOpacity }: DetailChildProps) => {
    const [swipe, setSwipevalue] = useState<number>(50);
    const [isShowResult, setShowResult] = useState(true);
    const [resultOpacity, setResultOpacity] = useState(100);
    const [isShowImage, setShowImage] = useState(true);
    const [imageOpacity, setImageOpacity] = useState(100);
    const [isShowCustom, setShowCustom] = useState(false);
    const [isResultAllChecked, setIsResultAllChecked] = useState(true);
    const [isCustomAllChecked, setIsCustomAllChecked] = useState(false);
    const [changeChildChecked, setChangeChildChecked] = useState(false);
    const [resultOpacityDisabled, setResultOpacityDisabled] = useState(false);
    const [imageOpacityDisabled, setImageOpacityDisabled] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        drawCompareImage && drawCompareImage(result.request.requestImageDataList[1].imageTileUrl);
    }, [])

    const hanldeChangSwipeValue = (value: number) => {
        swipeRef && (swipeRef.current = value);
        setSwipevalue(value);
        rerender && rerender();
    };

    //타일 이미지 Opacity 세팅
    const onChangeImageOpacity = (opacity: number) => {
        setImageOpacity(opacity);
        updateImageOpacity && updateImageOpacity(integerToDecimal(opacity, 2));
    }

    //타일 이미지 Visible 세팅
    const onChangeImageVisible = (is: boolean) => {
        setShowImage(is);
        updateImageVisible && updateImageVisible(is);
        setImageOpacityDisabled(!is);
    }

    //레이어 전체 Opacity 세팅
    const onChangeResultOpacity = (opacity: number) => {
        setResultOpacity(opacity);
        updateResultOpacity && updateResultOpacity(integerToDecimal(opacity, 2));
    }

    //레이어 전체 Visible 세팅 
    const onChangeLayerVisible = (is: boolean, isCustom: boolean) => {
        setChangeChildChecked(true);
        isCustom ? setShowCustom(is) : setShowResult(is);
        isCustom ? setIsCustomAllChecked(is) : setIsResultAllChecked(is);
        updateResultVisible && updateResultVisible(is, isCustom);
        !isCustom && setResultOpacityDisabled(!is);
    }

    //layer name별 Visible 세팅
    const onChangeLayerVisibleByName = (layerName: string, is: boolean) => {
        setChangeChildChecked(false);
        const isCustom = layerName.startsWith(LAYER_FACTORY.CUSTOM);
        updateResultVisibleByName && updateResultVisibleByName(layerName, is);
        if (!getResultLayers || !getCustomLayers) return;
        const layers = isCustom ? getCustomLayers() : getResultLayers();
        const visibleLayerLength = layers?.filter(i => i.getVisible()).length;
        const isAllVisible = layers?.length === visibleLayerLength;
        !isCustom && setResultOpacityDisabled(visibleLayerLength === 0);
        isCustom ? setShowCustom(isAllVisible) : setShowResult(isAllVisible);
        isAllVisible && (isCustom ? setIsCustomAllChecked(isAllVisible) : setIsResultAllChecked(isAllVisible));

    }

    const initCustomResult = useMemo(() => {
        if (!customLayerInfos || customLayerInfos.length === 0) return;
        return (
            <ResultControl title={t('panel.change_detection.geographic_data')} checked={isShowCustom} onChangeCheck={(e) => onChangeLayerVisible(e, true)}>
                <CustomResultContainer>
                    {updateResultVisible && makeLayerNameByType && customLayerInfos.map((layer, index) => {
                        return (<div key={index}><ResultControlItem title={layer.name} layerName={makeLayerNameByType(LAYER_FACTORY.CUSTOM, index)} parentsChecked={isCustomAllChecked} changeVisible={changeChildChecked} featureCount={layer.featureCount} onChangeCheck={onChangeLayerVisibleByName} layerType={LAYER_FACTORY.CUSTOM}></ResultControlItem></div>)
                    })}
                </CustomResultContainer>
            </ResultControl>

        )
    }, [customLayerInfos, isShowCustom, isCustomAllChecked])

    const initResult = useMemo(() => {
        const totalLength = result.featureCollections.reduce((acc, fc) => acc + fc.features.length, 0);
        return (
            <ResultControl title={t('panel.detail.detection_result')} checked={isShowResult} onChangeCheck={(e) => onChangeLayerVisible(e, false)} opacity={resultOpacity} onChangeOpacity={onChangeResultOpacity} opacityDisabled={resultOpacityDisabled}>
                <DetectionResultContainer total={totalLength.toLocaleString('ko-KR')}>
                    {makeLayerNameByType && result.featureCollections && result.featureCollections.map((layer, index) => {
                        return (<div key={index}><ResultControlItem title={layer.class} layerName={makeLayerNameByType(layerType, index)} parentsChecked={isResultAllChecked} changeVisible={changeChildChecked} featureCount={layer.features.length} onChangeCheck={onChangeLayerVisibleByName} layerType={layerType}></ResultControlItem></div>)
                    })}
                </DetectionResultContainer>
            </ResultControl>

        )
    }, [result, resultOpacity, isShowResult, isResultAllChecked, resultOpacityDisabled])

    return (
        <>
            <Information request={result.request} />
            {initResult}
            {initCustomResult}
            <ResultControl title="Data Images" checked={isShowImage} isFolder={false} opacity={imageOpacity} onChangeCheck={onChangeImageVisible} onChangeOpacity={onChangeImageOpacity} opacityDisabled={imageOpacityDisabled} />
            {isChangeDetection && createPortal(<Swipe dateStr={[result.request.requestImageDataList[0].imageCreatedDate, result.request.requestImageDataList[1].imageCreatedDate]} value={swipe} onChange={hanldeChangSwipeValue} />, document.getElementById('map-slider-root')!)}
        </>
    );
};

export default DetailPanel;
