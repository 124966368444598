import Menubar from 'components/menu/Menubar';
import OlLayer from 'components/ol/OlLayer';
import Panel from 'components/panel/Panel';
import { useProject } from 'hooks/feature/useProject';
import { useRequest } from 'hooks/feature/useRequest';
import { useEffect } from 'react';
import { CurrentMenuStore } from 'stores/CurrentMenu';
import styles from './Main.module.scss';

const Main = () => {
    const { fetchProjectList, setComponentByQueryParam } = useProject();
    const { actions } = CurrentMenuStore();
    const { menu } = CurrentMenuStore();
    const { requestList } = useRequest();
    useEffect(() => {
        init();
        initMainMenuState();
    }, []);

    const init = async () => {
        await fetchProjectList();
        setComponentByQueryParam();
    }

    const initMainMenuState = () => {
        actions.initMenu();
    };

    return (
        <div className={styles.main}>
            <Menubar></Menubar>
            <OlLayer></OlLayer>
            <Panel menu={menu} requestList={requestList}></Panel>
        </div>
    );
};

export default Main;
