import Search from "./search/Search";
import DatePicker from "components/common/datePicker/DatePicker";
import styles from "./SearchFilter.module.scss";
import FilterButton from "./filter/FilterButton";
import dayjs from "dayjs";
import { useFilter } from "hooks/feature/useFilter";

const SearchFilterBox = () => {
    const { search, actions, dateRange, keyword } = useFilter();

    const onChangeSearchKeyword = (input: string) => {
        actions.setKeyword(input);
    }

    const convert = (dateRange: [Date | null, Date | null]) => {
        return dateRange.map(date => {
            const newDate = dayjs(date).format('YYYY-MM-DD');
            return newDate === 'Invalid Date' ? null : newDate;
        });
    };

    const onChangeDateRange = (range: [Date | null, Date | null]) => {
        actions.setDateRange(range);
        const convertDate = convert(range);
        actions.setStartDate(convertDate[0]);
        actions.setEndDate(convertDate[1]);
        search();
    }

    return <div className={styles.container}>
        <Search keyword={keyword} onChange={onChangeSearchKeyword} onClicksearch={search} />
        <div className={styles['filter-wrap']}>
            <DatePicker onUpdateRange={onChangeDateRange} range={dateRange} />
            <FilterButton></FilterButton>
        </div>
    </div>
}
export default SearchFilterBox;