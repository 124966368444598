import { create } from "zustand";

type Loading = {
    isLoading: boolean;
    setLoading: (isLoading: boolean) => void;   
};


export const LoadingStore = create<Loading>((set, get) => ({
    isLoading: false,
    setLoading: (isLoading) => set({ isLoading }),
}));