
import { FilterStateStore } from "stores/FilterState";
import { useRequest } from './useRequest';
import { SelectedProjectStore } from "stores/SelectedProject";


export const useFilter = () => {
    const { status, task, filterList, statusSize, taskSize, actions, dateRange, keyword } = FilterStateStore();
    const { getProjectId } = SelectedProjectStore()
    const { loadRequestListByProject } = useRequest();

    const search = async () => {
        const pId = getProjectId();
        if (!pId) return;
        actions.setPage(1);
        await loadRequestListByProject(pId);
    }

    const clear = () => {
        actions.clear();
    }

    const add = (type: string, state: string) => {
        actions.add(type, state);
    }

    const remove = (str: string) => {
        actions.remove(str);
    }

    return {
        keyword,
        status,
        task,
        dateRange,
        filterList,
        statusSize,
        taskSize,
        actions,
        search,
        clear,
        add,
        remove
    }
}