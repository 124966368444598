import styles from './Alert.module.scss';
import Dim from '../dim/Dim';
import Button from 'components/common/button/Button';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { AlertProps } from './Alert.type';

const Alert = ({ children, confirmTitle, cancelTitle, onConfirm, onClose }: AlertProps) => {
    const { t } = useTranslation();

    const viewingConfirmTitle = useMemo(() => (typeof confirmTitle === 'undefined' ? t('text_confirm') : confirmTitle), [confirmTitle, t]);

    const buttonWidth = useMemo(() => {
        return confirmTitle && cancelTitle ? 120 : 240;
    }, [confirmTitle, cancelTitle]);

    const handleClose = () => {
        onClose();
    };

    return (
        <Dim>
            <div className={styles['alert__container']}>
                <div className={styles['alert__close-wrap']}>
                    <span className={styles['icon']} onClick={handleClose}></span>
                </div>
                <div className={styles['alert__message-wrap']}>{children}</div>
                <div className={styles['alert__button-wrap']}>
                    {cancelTitle && (
                        <Button width={buttonWidth} height={52} colorType="white" onClick={handleClose}>
                            {cancelTitle}
                        </Button>
                    )}
                    <Button width={buttonWidth} height={52} colorType="black" onClick={onConfirm}>
                        {viewingConfirmTitle}
                    </Button>
                </div>
            </div>
        </Dim>
    );
};

export default Alert;
