import React from "react";
import MainMenuList from "components/menu/MainMenuList/MainMenuList";
import { MenuList, UserMenuList } from "data/MenuList";
import styles from './Menubar.module.scss';
import UserMenu from "./userMenuList/UserMenuList";
const Menubar = () => {
    return <div className={styles.container}>
        <MainMenuList itemList={MenuList}></MainMenuList>
        <UserMenu itemList={UserMenuList}></UserMenu>
    </div>
}

export default Menubar;