import React, { useCallback } from 'react';
import styles from './Search.module.scss';
import { useTranslation } from 'react-i18next';
import { KEYS } from 'constants/Constants';

type TStarchProps = {
    keyword: string;
    onChange: (input: string) => void;
    onClicksearch: () => void;
};

const Search = ({ keyword, onChange, onClicksearch }: TStarchProps) => {
    const { t } = useTranslation();

    const onChangeSearchValue = useCallback((event: React.ChangeEvent) => {
        if (!(event.target instanceof HTMLInputElement)) return;
        onChange(event.target.value);
    }, [keyword]);

    const onClickInputClear = () => {
        onChange('');
    };

    const onKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement> ) => {
        switch (e.key) {
            case KEYS.ENTER:
                onClicksearch();
                break;
            case KEYS.ESCAPE:
                onClickInputClear();
                break;
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.wrap}>
                <input placeholder={t(`panel.search.placeholder`)} value={keyword} onChange={onChangeSearchValue} onKeyDown={onKeyDownInput} />
                {keyword.length > 0 && <span className={styles.clear} onClick={onClickInputClear}></span>}
            </div>
            <button onClick={onClicksearch}>{t(`panel.search.placeholder`)}</button>
        </div>
    );
};

export default Search;
