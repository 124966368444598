import React, { useEffect, useState } from "react";
import styles from "./CheckBox.module.scss";
import classNames from "classnames";
type TCheckBoxProps = {
    type?: string;
    label: string;
    state: string;
    isChecked?: boolean;
    disabled?: boolean;
    onChange: (state: string, checked: boolean) => void;
}

const CheckBox = ({ type, label, state, isChecked = false, disabled, onChange }: TCheckBoxProps) => {
    const [is, setCheckecd] = useState(isChecked);
    useEffect(() => {
        setCheckecd(isChecked);
    }, [isChecked])

    const onChangeChecked = () => {
        onChange(state, !isChecked);
    }

    return <label className={styles['check-box-wrap']}>
        <input className={classNames([styles.checkbox, styles[state]])} type={'checkbox'} checked={is} disabled={disabled} onChange={onChangeChecked} />
        {label}
    </label>
}

export default CheckBox;