import { postLogout, postRefreshToken } from 'api/Authentication';
import { MemberStore } from 'stores/Member';
import { redirectAdmin, setTokens } from 'utils/AuthenticationUtil';

export function useAuthentication() {
    const { setId } = MemberStore();

    const setupToken = async () => {
        return await issueToken();
    };

    const issueToken = async () => {
        const { memberId, tokens } = await postRefreshToken();
        setId(memberId);
        tokens.accessToken && setTokens(tokens);
        return Boolean(tokens.accessToken);
    };

    const expireToken = async () => {
        const result = await postLogout();
        // TODO: 로그아웃 실패 예외처리
        if (!result) return;
        redirectAdmin();
    };

    return {
        setupToken,
        expireToken,
    };
}
