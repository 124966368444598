import classNames from "classnames";
import { useOpenLayers } from "hooks/ol/useOpenLayers";
import { fromLonLat } from "ol/proj";
import { useCallback, useEffect, useState } from "react";
import { mapStore } from "stores/Map";
import styles from "./SearchInput.module.scss";

const SearchInput = () => {
  const [inputValue, setInputValue] = useState('');
  const [isClearOn, setIsClearOn] = useState(false);
  const { setViewAnimate, setPoiIcon, clearPoiLayer } = useOpenLayers();
  const { map } = mapStore();

  useEffect(() => {
    if (!map) return;
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') { onClearClick(); }
    });
  }, [map]);

  useEffect(() => {
    setIsClearOn(inputValue.length > 0);
  }, [inputValue])

  const onChangeSearchValue = useCallback((event: React.ChangeEvent) => {
    if (!(event.target instanceof HTMLInputElement)) return;
    setInputValue(event.target.value);
  }, [inputValue]);

  const onSearchClick = () => {
    if (inputValue.length === 0) return;
    const value = inputValue.split(',');
    if (isNaN(Number(value[0])) || isNaN(Number(value[1]))) return;
    const coordinate = fromLonLat([Number(value[1]), Number(value[0])]);
    setViewAnimate(coordinate);
    setPoiIcon(coordinate);
  }

  const onClearClick = () => {
    clearPoiLayer();
    setInputValue('');
  }

  const onSearchKeydown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      onSearchClick();
    }
  }

  return (
    <div className={styles["search-input__container"]}>
      <input className={styles["search-input"]} placeholder="Input coordinate" value={inputValue} onChange={onChangeSearchValue} onKeyDown={(e) => onSearchKeydown(e)}></input>
      {isClearOn &&
        <span className={classNames(styles["icon"], styles["clear"])} onClick={onClearClick}></span>
      }
      <span className={classNames(styles["icon"], styles["search"])} onClick={onSearchClick}></span>
    </div>
  )
}

export default SearchInput;