import { OL_CHANGE_DETEACTION_STROKE_RESOLUTION, OL_CIRCLE_RADIUS_RESOLUTION, OL_CLASS_COLOR, OL_CUSTOM_STROKE_COLOR_ARR, OL_SEGMENTATION_STROKE_RESOLUTION, OL_STROKE_COLOR, OL_STROKE_COLOR_ARR, OL_STROKE_WIDTH } from 'constants/Constants';
import { FeatureLike } from 'ol/Feature';
import Layer from 'ol/layer/Layer';
import WebGLPointsLayer from "ol/layer/WebGLPoints";
import { FrameState } from 'ol/Map';
import WebGLVectorLayerRenderer from 'ol/renderer/webgl/VectorLayer.js';
import VectorSource from 'ol/source/Vector';
import { checkStrClass } from './Utils';

export const LAYER_FACTORY = {
    // BUILDING: 'building',
    // ROAD: 'road',
    SEGMENTATION: 'building',
    CHANGE: 'change',
    OBJECT: 'object',
    CUSTOM: 'custom',
    POINT: 'point'
} as const;
export type TLayerFactory = (typeof LAYER_FACTORY)[keyof typeof LAYER_FACTORY];

export class WebGLLayerFactory {
    getGLLayerByType(type: TLayerFactory, options: any, index?: number, className?: string) {
        switch (type) {
            case LAYER_FACTORY.CUSTOM:
                if (index !== undefined) {
                    return FactoryMap[type](options, index);
                }
                break;
            case LAYER_FACTORY.CHANGE:
                if (index !== undefined && className) {
                    return FactoryMap[type](options, index, className);
                }
                break;
            default: 
                return FactoryMap[type](options);
        }
    }
}

const FactoryMap = {
    building(options: any) {
        return new BuildingSegmentationLayer(options);
    },
    road(options: any) {
        return new LoadSegmentationLayer(options);
    },
    change(options: any, styleIndex: number, className: string) {
        return new ChangeDetectionLayer(options, styleIndex, className);
    },
    object(options: any) {
        return new ObjectDetectionLayer(options);
    },
    custom(options: any, index: number) {
        return new CustomLayer(options, index as number);
    },
    point(options: any) {
        const vectorSource = new VectorSource<FeatureLike>({});
        const pointLayer = new WebGLPointsLayer({
            source: vectorSource,
            style: POINT_STYLE,
            zIndex: 2
        })
        return pointLayer;
    }
};

export const BBOX_STYLE = {
    'stroke-color': ['*', ['get', 'COLOR'], OL_STROKE_COLOR.BBOX],
    'stroke-width': OL_STROKE_WIDTH.BBOX
} as const;

const LOAD_STYLE = {
    // 'fill-color': ['*', ['get', 'COLOR'], [255, 255, 255, 0.15]],
    'stroke-color': ['*', ['get', 'COLOR'], OL_STROKE_COLOR.ROAD_SEGMENTATION],
    'stroke-width': ['interpolate', ['exponential', 2], ['zoom'], ...OL_SEGMENTATION_STROKE_RESOLUTION]
};

const BUILDING_STYLE = {
    'fill-color': ['*', ['get', 'COLOR'], [255, 255, 255, 0.15]],
    'stroke-color': ['*', ['get', 'COLOR'], OL_STROKE_COLOR.BUILDING_SEGMENTATION],
    'stroke-width': ['interpolate', ['exponential', 2], ['zoom'], ...OL_SEGMENTATION_STROKE_RESOLUTION]
};
const OBJECT_STYLE = {
    'stroke-color': ['*', ['get', 'COLOR'], OL_STROKE_COLOR.OBJECT_DETECTION],
    'stroke-width': OL_STROKE_WIDTH.OBJECT_DETECTION,
    'fill-color': ['*', ['get', 'COLOR'], [255, 255, 255, 0.15]],
};
const CHANGE_STYLE = {
    'radius': ['interpolate', ['exponential', 2], ['zoom'], 11.5, 0.5, 18, 3.6, 21, 13.5],
    'fill-color': ['*', ['get', 'COLOR'], [255, 255, 255, 0.15]],
    'stroke-color': OL_STROKE_COLOR.CHANGE_DETECTION,
    'stroke-width': ['interpolate', ['exponential', 2], ['zoom'], ...OL_CHANGE_DETEACTION_STROKE_RESOLUTION]
};
const POINT_STYLE = {
    'circle-radius': ['interpolate', ['exponential', 2], ['zoom'], ...OL_CIRCLE_RADIUS_RESOLUTION],
    'circle-fill-color': [255, 255, 255, 0.15],
    'circle-stroke-color': OL_CLASS_COLOR.BUILDING,
    'circle-stroke-width': ['interpolate', ['exponential', 2], ['zoom'], ...OL_CHANGE_DETEACTION_STROKE_RESOLUTION],
}


class ExtendsWebGLLayerRenderer extends WebGLVectorLayerRenderer {
    override prepareFrame(frameState: FrameState): boolean {
        try {
            super.prepareFrame(frameState);
        } catch (e) {
            console.log(e);
        } finally {
            return true;
        }
    }

    override prepareFrameInternal(frameState: FrameState) {
        try {
            super.prepareFrameInternal(frameState);
        } catch (e) {
            console.log(e);
        } finally {
            return true;
        }
    }
}

class ObjectDetectionLayer extends Layer {
    createRenderer(): WebGLVectorLayerRenderer {
        return new WebGLVectorLayerRenderer(this, { style: OBJECT_STYLE });
    }
}

class LoadSegmentationLayer extends Layer {
    createRenderer(): WebGLVectorLayerRenderer {
        return new WebGLVectorLayerRenderer(this, { style: LOAD_STYLE });
    }
}

class BuildingSegmentationLayer extends Layer {
    createRenderer(): WebGLVectorLayerRenderer {
        return new WebGLVectorLayerRenderer(this, { style: BUILDING_STYLE });
    }
}

class ChangeDetectionLayer extends Layer {
    constructor(options: any, private styleIndex: number, private className: string) {
        super(options);
        this.setZIndex(1);
    }
    createRenderer(): WebGLVectorLayerRenderer {
        const myclass = this.className;
        const isClass = checkStrClass(myclass);
        const myStyle = { ...CHANGE_STYLE, 'stroke-color': isClass ? OL_CLASS_COLOR[myclass] : OL_STROKE_COLOR_ARR.CHANGE_DETECTION[this.styleIndex] };
        return new WebGLVectorLayerRenderer(this, { style: myStyle });
    }
}

class CustomLayer extends Layer {
    constructor(options: any, private styleIndex: number) {
        super(options);
        this.setZIndex(0);
        this.setVisible(false);
    }
    createRenderer(): WebGLVectorLayerRenderer {
        const CUSTOM_STYLE = {
            'fill-color': ['*', ['get', 'COLOR'], [255, 255, 255, 0.15]],
            'stroke-color': ['*', ['get', 'COLOR'], OL_CUSTOM_STROKE_COLOR_ARR[this.styleIndex ?? 0]],
            'stroke-width': ['interpolate', ['exponential', 2], ['zoom'], ...OL_SEGMENTATION_STROKE_RESOLUTION],
        }
        return new WebGLVectorLayerRenderer(this, { style: CUSTOM_STYLE });
    }
}