import { useSearchParams } from "react-router-dom";

export const useQueryParam = () => {
    const [searchParams] = useSearchParams();
    const getQueryParamCollection = () => {
        const collection = new Map<string, number>();
        Array.from(searchParams).forEach((param) => collection.set(param[0], parseInt(param[1])));
        return collection;
    }

    return {
        getQueryParamCollection
    }
}