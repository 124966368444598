import React, { useEffect, useState } from "react";
import styles from "./List.module.scss";
import ListHeader from "./header/ListHeader";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { TListProps } from "./List.type";
import { useTranslation } from 'react-i18next';
import { TOAL_BOX_HEIGHT } from "constants/Constants";

const List = ({ children, onScroll, hasMore }: TListProps) => {
    const { t } = useTranslation();
    const [chipSize, setChipSize] = useState<number>(TOAL_BOX_HEIGHT);
    useEffect(() => {
    }, [chipSize]);

    const isEmptyChildren = (): boolean => {
        return children.length === 0;
    }

    return <div className={styles['list-box']}>
        <ListHeader count={children.length ?? 0} setSize={setChipSize}></ListHeader>
        <div id={'scrollList'} className={classNames([styles['-list'], styles[`h-${chipSize}`]])}>
            <InfiniteScroll dataLength={children.length} next={onScroll} hasMore={hasMore} loader={<></>} scrollableTarget={'scrollList'}>
                {children}
            </InfiniteScroll>
            {isEmptyChildren() && <span className={styles.placeholder}>{t(`panel.noData`)}</span>}
        </div>
    </div>
}

export default List;