import { create } from "zustand";

type PanelState = {
    isOpen: boolean;
    setState: (is: boolean) => void;
};


export const PanelStateStore = create<PanelState>((set) => ({
    isOpen: false,
    setState: (isOpen) => set({isOpen}),
}));