import classNames from 'classnames';
import { TASK_NAME } from 'constants/Constants';
import styles from "./TaskBadgeBox.module.scss";
import { TTaskBadgeBoxProp } from './TaskBadgeBox.type';
const TaskBadgeBox = ({ task, coreName }: TTaskBadgeBoxProp) => {

    return <div className={styles.container}>
        <div className={classNames([styles.badge, styles[TASK_NAME[task]]])}>{coreName}</div>
    </div>
}

export default TaskBadgeBox;