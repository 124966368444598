import { ButtonHTMLAttributes, MouseEvent, ReactNode } from "react";

export type ButtonProps = {
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  children: ReactNode;
  colorType?: ButtonColorType;
  width: number;
  height: number;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const BUTTON_COLOR_TYPE = {
  blue: "blue",
  black: "black",
  white: "white",
  red: "red",
} as const;

export type ButtonColorType = typeof BUTTON_COLOR_TYPE[keyof typeof BUTTON_COLOR_TYPE];
