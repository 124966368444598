import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import styles from "./ZoomControl.module.scss";
import { MAP_OPTIONS } from "constants/Constants";
import { mapStore } from "stores/Map";

const ZoomControl = () => {
  const { map } = mapStore();
  const [zoom, setZoom] = useState(MAP_OPTIONS.INITIAL_ZOOM);

  useEffect(() => {
    if (map) {
      map.on("moveend", handleChangedZoom);
    }
  }, [map]);

  const zoomPercent = useMemo(
    () =>
      ((zoom - MAP_OPTIONS.MIN_ZOOM) * 100) /
      (MAP_OPTIONS.MAX_ZOOM - MAP_OPTIONS.MIN_ZOOM),
    [zoom]
  );

  const handleChangedZoom = () => {
    const zoom = map?.getView().getZoom();
    typeof zoom === "number" && setZoom(zoom);
  };

  const handleChangeZoom = (changeZoom: number) => {
    map?.getView().setZoom(changeZoom);
  };

  return (
    <div className={classNames(styles["zoom-control__container"])}>
      <div
        className={styles["-plus"]}
        onClick={() => handleChangeZoom(zoom + 1)}
      >
        <button
          className={classNames(styles["-plus__button"], {
            [styles["disabled"]]: zoom === MAP_OPTIONS.MAX_ZOOM,
          })}
        ></button>
      </div>
      <div className={styles["-bar"]}>
        <div className={styles["-content"]}>
          <div
            className={styles["-range"]}
            style={{
              height: `${zoomPercent}%`,
            }}
          ></div>
          <span
            className={styles["-handle"]}
            style={{
              bottom: `${zoomPercent}%`,
            }}
          ></span>
        </div>
      </div>
      <div
        className={styles["-minus"]}
        onClick={() => handleChangeZoom(zoom - 1)}
      >
        <button
          className={classNames(styles["-minus__button"], {
            [styles["disabled"]]: zoom === MAP_OPTIONS.MIN_ZOOM,
          })}
        ></button>
      </div>
    </div>
  );
}

export default ZoomControl;