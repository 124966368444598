import CheckBox from 'components/common/checkBox/CheckBox';
import { CLASS_KOR, OL_CLASS_COLOR, OL_STROKE_COLOR_ARR } from 'constants/Constants';
import { useEffect, useMemo, useState } from 'react';
import { checkStrClass } from 'utils/Utils';
import { LAYER_FACTORY } from 'utils/WebGLLayerFactory';
import styles from "./ResultControlItem.module.scss";
import { ResultControlItemProps } from './ResultControlItem.type';

const ResultControlItem = ({ title, layerName, featureCount, layerType, parentsChecked, changeVisible, onChangeCheck }: ResultControlItemProps) => {
    const [isShowResult, setShowResult] = useState(parentsChecked);
    const [colorArr, setColorArr] = useState<string[]>([]);
    const [classType, setClassType] = useState('');

    useEffect(() => {
        if (!layerType) return;
        setColorByLayerType();
    }, [layerType]);

    useEffect(() => {
        setClassType(checkStrClass(title) ? CLASS_KOR[title] : title);
    }, [title])

    const getColor = useMemo(() => { //[MEMO] lucy: 클래스 색상을 맞추기 위한 임시 하드코딩임. 나중에 바뀌어야 함 2024.11.05
        const isClass = checkStrClass(title);
        return isClass ? OL_CLASS_COLOR[title] : colorArr[Number(layerName.charAt(layerName.length - 1))]
    }, [title, layerName, colorArr]);

    const setColorByLayerType = () => {
        switch (layerType) {
            case LAYER_FACTORY.CUSTOM:
                setColorArr(OL_STROKE_COLOR_ARR.CUSTOM);
                break;
            case LAYER_FACTORY.CHANGE:
                setColorArr(OL_STROKE_COLOR_ARR.CHANGE_DETECTION);
                break;
            case LAYER_FACTORY.OBJECT:
                setColorArr(OL_STROKE_COLOR_ARR.OBJECT_DETECTION);
                break;
            case LAYER_FACTORY.SEGMENTATION:
                setColorArr(OL_STROKE_COLOR_ARR.SEGMENTATION);
                break;
        }
    }

    useEffect(() => {
        parentsChecked !== undefined && changeVisible && setShowResult(parentsChecked);
    }, [parentsChecked, changeVisible]);

    const onChangeCustomVisible = (is: boolean) => {
        setShowResult(is);
        onChangeCheck && layerName && onChangeCheck(layerName, is);
    }

    const getCount = () => {
        return featureCount.toLocaleString('ko-KR');
    }

    return (
        <div className={styles['container']}>
            <div className={styles['title-area']}>
                <CheckBox label={classType} checked={isShowResult} onChange={(e) => onChangeCustomVisible(e.target.checked)} circleColor={getColor} />
            </div>
            <div className={styles['count']}>{getCount()}</div>
        </div>
    );
};

export default ResultControlItem;

