import axios, { AxiosError, AxiosInstance, HttpStatusCode } from 'axios';
import { HTTP_HEADER_ACCESS_TOKEN } from 'constants/Constants';
import { redirectAdmin, setTokens } from 'utils/AuthenticationUtil';
import { getPath } from 'utils/Utils';
import { postLogout, postRefreshToken } from './Authentication';

interface CustomInstance extends AxiosInstance {
    get<T>(...params: Parameters<AxiosInstance['get']>): Promise<T>;
    post<T>(...params: Parameters<AxiosInstance['post']>): Promise<T>;
    put<T>(...params: Parameters<AxiosInstance['put']>): Promise<T>;
    delete<T>(...params: Parameters<AxiosInstance['delete']>): Promise<T>;
}

const api: CustomInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
});

api.interceptors.request.use(
    async (res) => {
        const config = res;
        config.headers['Content-Type'] = 'application/json; charset=utf-8';
        //TODO:2024.07.24 comment by kay : DABEEO-MEMBER-ID 제거 예정
        config.headers['DABEEO-MEMBER-ID'] = '1';
        config.headers['DABEEO-COMPANY-ID'] = getPath();

        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response.data,
    async (error: AxiosError) => (error.config?.baseURL === process.env.REACT_APP_BASE_URL ? await handleError(error) : Promise.reject(error))
);

export const kakaoApi: CustomInstance = axios.create({
    baseURL: process.env.REACT_APP_KAKAO_URL
});

kakaoApi.interceptors.request.use(
    async (res) => {
        const config = res;
        config.headers['Authorization'] = `KakaoAK ${process.env.REACT_APP_KAKAO_API_KEY}`

        return config;
    },
    (error) => Promise.reject(error)
)

async function handleError(error: AxiosError) {
    if (!error.response) return;
    if (error.response.status === HttpStatusCode.Unauthorized) {
        const { tokens } = await postRefreshToken();

        if (tokens.accessToken) {
            setTokens(tokens);
            const originalRequest = error.config;
            // TODO: 예외처리
            if (!originalRequest) return;
            originalRequest.headers[HTTP_HEADER_ACCESS_TOKEN] = `Bearer ${tokens.accessToken}`;

            return axios(originalRequest);
        }

        // TODO: 만료
        await postLogout();
        redirectAdmin();
    }

    return Promise.reject(error);
}

export default api;
