
import { INTERACTION_PROPS, OL_STROKE_COLOR, OL_STROKE_WIDTH, TASK, TTaskKey } from "constants/Constants";
import { Map } from "ol";
import { transform } from "ol/proj";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { InteractionTypes } from "types/TInterraction";
import { LayerPropsType, LayerTypes } from "types/TLayer";
import { LAYER_FACTORY } from "./WebGLLayerFactory";

export const convert4326To3857 = (coordinates: number[]) => {
    return transform(coordinates, "EPSG:4326", "EPSG:3857");
}

export const convert3857To4326 = (coordinates: number[]) => {
    return transform(coordinates, "EPSG:3857", "EPSG:4326");
}

export const getLayerByProps = (map: Map, propKey: LayerPropsType, value: LayerTypes | string) => {
    return map?.getAllLayers().filter(layer => layer.get(propKey) === value);
}

export const getInteractionByProps = (map: Map, type: InteractionTypes) => {
    return map?.getInteractions().getArray().find(i => i.get(INTERACTION_PROPS.INTERACTION_TYPE) === type);
}

export const onUpdateBBox = (feature: any, resolution: number) => {
    const width = OL_STROKE_WIDTH.BBOX / resolution;
    return new Style({
        stroke: new Stroke({
            color: OL_STROKE_COLOR.BBOX,
            width: (width >= OL_STROKE_WIDTH.BBOX ? OL_STROKE_WIDTH.BBOX : width <= 2 ? 2 : width)
        })
    })
}

export const getLayerByType = (task: TTaskKey) => {
    if (TASK[task] === TASK.SEGMENTATION) return LAYER_FACTORY.SEGMENTATION;
    else if (TASK[task] === TASK.OBJECT_DETECTION) return LAYER_FACTORY.OBJECT;
    else if (TASK[task] === TASK.CHANGE_DETECTION) return LAYER_FACTORY.CHANGE;
    else return LAYER_FACTORY.CUSTOM;
}