import { TProject } from "types/TProject";
import { create } from "zustand";

type TSelectedProject = {
    project: TProject | null;
    isInit: boolean;
    getProjectId: () => number | null;
    setSelected: (info: TProject | null) => void;
    setIsInit: (is: boolean) => void;
    clear: () => void;
};


export const SelectedProjectStore = create<TSelectedProject>((set, get) => ({
    project: null,
    isInit: false,
    getProjectId: () => {
        return get().project?.projectId ?? null;
    },
    setIsInit: (is: boolean) => set({ isInit: is }),
    setSelected: (selected) => set({ project: selected }),
    clear: () => {
        get().setSelected(null);
    }
}));