import { create } from "zustand";

export type CustomLayerInfo = {
  name: string;
  featureCount: number;

  // layerName: string;
}

type CustomLayer = {
  customLayerInfos: CustomLayerInfo[];
  setCustomLayerInfos: (customLayerInfos: CustomLayerInfo[]) => void;
}

export const customLayerStore = create<CustomLayer>((set, get) => ({
  customLayerInfos: [],
  setCustomLayerInfos: (customLayerInfos) => set({ customLayerInfos })
}))

