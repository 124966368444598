import { TRequestContent, TRequestInfo } from "types/TRequest";
import { create } from "zustand";

export type TRequestList = {
    requestInfo: TRequestInfo | null;
    requestList: Array<TRequestContent>;
    requestCollection: Map<number, TRequestContent>;
    toCollection: (list: Array<TRequestContent>) => Map<number, TRequestContent>;
    setRequest: (request: TRequestInfo | null) => void;
    setRequestCollection: (data: Map<number, TRequestContent>) => void;
    setRequestList: (list: Array<TRequestContent>) => void;
    addRequestCollection: (data: Map<number, TRequestContent>) => void;
    addRequestList: (list: Array<TRequestContent>) => void;
    getRequestCollection: () => Map<number, TRequestContent>;
    clear: () => void;
};


export const RequestStore = create<TRequestList>((set, get) => ({
    requestInfo: null,
    requestList: new Array<TRequestContent>(),
    requestCollection: new Map<number, TRequestContent>(),
    toCollection: (list: Array<TRequestContent>) => {
        const map = new Map<number, TRequestContent>();
        list.forEach((request) => map.set(request.id, request));
        return map;
    },
    setRequest: (t) => {
        t ? get().setRequestCollection(get().toCollection(t.content)) : get().clear();
        set({ requestInfo: t })
    },
    setRequestCollection: (data) => {
        set({ requestCollection: data });
        get().setRequestList(Array.from(data.values()));
    },
    setRequestList: (list) => set({ requestList: list }),
    addRequestCollection: (data) => {
        set({ requestCollection: new Map([...get().requestCollection.entries(), ...data.entries()]) });
        get().addRequestList(Array.from(data.values()));
    },
    addRequestList: (list) => {
        set({ requestList: get().requestList.concat(...list) })
    },
    getRequestCollection: () => get().requestCollection,
    clear: () => {
        get().setRequestCollection(new Map<number, TRequestContent>());
    }
}));