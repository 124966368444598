import { CLASS_KOR, GEO_JSON_PROPERTIES } from 'constants/Constants';
import { useTranslation } from 'react-i18next';
import { checkStrClass } from 'utils/Utils';
import styles from './ResultOverlay.module.scss';
import { ResultOverlayProps } from './ResultOverlay.type';

const ResultOverlay = ({ feature, coordinates, roadAddress }: ResultOverlayProps) => {
    const { t } = useTranslation();
    const classType = feature.get(GEO_JSON_PROPERTIES.CLASS);

    const handleCopyCoordinates = async (value: string) => {
        try {
            await navigator.clipboard.writeText(value);
        } catch (e) {
            const id = 'dummy';
            const input = document.createElement('input');
            input.type = 'text';
            input.id = id;
            input.value = value;
            input.style.position = 'fixed';
            input.style.top = '-100px';
            document.body.appendChild(input);

            const obj = document.getElementById(id);
            if (!(obj instanceof HTMLInputElement)) return;
            obj.select();
            document.execCommand('copy');
            document.body.removeChild(obj);
        }
    };

    const properties = [
        { key: GEO_JSON_PROPERTIES.CLASS, label: t('overlay.result.class'), isCopy: false, value: checkStrClass(classType) ? CLASS_KOR[classType] : "" },
        { key: GEO_JSON_PROPERTIES.PNU, label: t('overlay.result.pnu'), isCopy: true, value: feature.get(GEO_JSON_PROPERTIES.PNU) },
        { key: GEO_JSON_PROPERTIES.ADDR, label: t('overlay.result.address'), isCopy: true, value: feature.get(GEO_JSON_PROPERTIES.ADDR) },
        { key: GEO_JSON_PROPERTIES.JIBUN, label: t('overlay.result.jibun'), isCopy: true, value: feature.get(GEO_JSON_PROPERTIES.JIBUN) },
        { key: GEO_JSON_PROPERTIES.OWNER_NM, label: t('overlay.result.gbn'), isCopy: false, value: feature.get(GEO_JSON_PROPERTIES.OWNER_NM) },
        { key: GEO_JSON_PROPERTIES.JIMOK, label: t('overlay.result.jimok'), isCopy: false, value: feature.get(GEO_JSON_PROPERTIES.JIMOK) }
    ];

    const setValue = (value: string | null | undefined, isCopy: boolean) => {
        return value ? (
            <>
                <span>{value}</span>
                {isCopy && (
                    <div
                        className={styles['icon']}
                        onClick={() => handleCopyCoordinates(value)}
                    ></div>
                )}
            </>
        ) : (
            <span>-</span>
        );
    };

    const checkProperty = (json: object, key: string) => {
        return json.hasOwnProperty(key);
    };

    return (
        <div className={styles['overlay-container']}>
            <table>
                <thead>
                    <tr>
                        <th>{t('overlay.result.coordinates')}</th>
                        <th>{t('overlay.result.road_address')}</th>
                        {properties.map(({ key, label }) =>
                            checkProperty(feature.getProperties(), key) && <th key={key}>{label}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {setValue(`${coordinates[1].toFixed(6)}, ${coordinates[0].toFixed(6)}`, true)}
                        </td>
                        <td>
                            {setValue(roadAddress, true)}
                        </td>
                        {properties.map(({ key, value, isCopy }) =>
                            checkProperty(feature.getProperties(), key) && (
                                <td key={key}>
                                    {setValue(value, isCopy)}
                                </td>
                            )
                        )}
                    </tr>
                </tbody>
            </table>
        </div>
    );

};

export default ResultOverlay;
