import { LANGUAGE, Language } from 'constants/Constants';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import transEn from './lang/en.json';
import transKo from './lang/ko.json';

const resources = {
    ko: { translation: transKo },
    en: { translation: transEn }    
};

i18n.use(initReactI18next).init({
    resources,
    react: {
        useSuspense: false,
    },
    lng: localStorage.getItem(LANGUAGE) || Language.EN,    
    fallbackLng: Language.EN,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
