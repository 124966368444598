import { TRequestContent } from "types/TRequest";
import { create } from "zustand";

type TSelected = {
    request: TRequestContent | null;
    setSelected: (request: TRequestContent | null) => void;
    getSelected: () => TRequestContent | null;
    clear: () => void;
};


export const SelectedRequestStore = create<TSelected>((set, get) => ({
    request: null,
    setSelected: (selected) => set({ request: selected }),
    getSelected: () => get().request,
    clear: () => {
        get().setSelected(null);
    }
}));