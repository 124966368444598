import styles from './MainMenuList.module.scss';
import { TMenuItem } from "components/menu/menuItem/MenuItem.type";
import MenuItem from "../menuItem/MenuItem";
import { TMenubar } from '../TMenubar';
import React from 'react';
import { MENU_STATE, TMainMenu, TUserMenu } from 'constants/Constants';
import { CurrentMenuStore } from 'stores/CurrentMenu';
import { PanelStateStore } from 'stores/PanelState';

const MainMenuList = (prop: TMenubar<TMenuItem>) => {
    const { menu, actions } = CurrentMenuStore();
    const { isOpen, setState } = PanelStateStore();
    const onClickMenu = <T extends TMainMenu | TUserMenu,>(event: React.MouseEvent, state: T) => {
        if (menu === state) setState(!isOpen)
        else {
            actions.setMenu(state);
            setState(true);
        }
    }

    return <div className={styles.container}>
        {prop.itemList.length > 0 && prop.itemList.map(({ type, name, active }: TMenuItem, idx: number) => {
            return <MenuItem type={type} name={name} active={active} key={`${type}-${idx}`} onClick={onClickMenu} state={MENU_STATE.MAIN}></MenuItem>
        })}
    </div>;
}

export default MainMenuList;