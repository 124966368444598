import React, { useEffect, useState } from "react";
import { default as FilterCheckBox } from "./CheckBox";
import styles from "./CheckBoxGroup.module.scss";
import classNames from "classnames";
import { FilterStateStore } from "stores/FilterState";

type TCheckBoxGrpProps = {
    entity: Array<[string, string]>;
    groupLabel: string;
    line?: 'left' | 'right',
    checkCount: number;
    add: (type: string, state: string) => void;
    remove: (state: string) => void
}

const CheckBoxGroup = ({ entity, groupLabel, line, checkCount, add, remove }: TCheckBoxGrpProps) => {
    const { actions } = FilterStateStore();
    const [checkCnt, setCheckCnt] = useState<number>(checkCount);
    const [is, setGrpChecked] = useState<boolean>(checkCnt === entity.length);

    useEffect(() => {
        initGorupCheckBox();
    }, [checkCount]);

    const initGorupCheckBox = () => {
        setGrpChecked(actions.getSize(groupLabel) === entity.length);
    }

    const onChangeChecked = (state: string, checked: boolean) => {
        checked ? add(groupLabel, state) : remove(state);
        setCheckCnt(actions.getSize(groupLabel));
        initGorupCheckBox();
    }

    const initCheckList = () => {
        return entity.map(([key, value]) => {
            return <FilterCheckBox type={groupLabel} label={value} isChecked={actions.get(groupLabel).has(key)} state={key} onChange={onChangeChecked} key={`${key}-${value}`}></FilterCheckBox>
        })
    };

    const onChanageGroupChecked = (state: string, checked: boolean) => {
        for (const [key, value] of entity) checked ? actions.add(groupLabel, key) : actions.remove(key);
        setGrpChecked(checked);
        setCheckCnt(actions.getSize(groupLabel));
        // load();
    }

    return <div className={classNames([styles.container, line ? styles[`-${line}`] : ''])}>
        <FilterCheckBox label={groupLabel} state={'all'} isChecked={is} onChange={onChanageGroupChecked}></FilterCheckBox>
        {initCheckList()}
    </div>
}

export default CheckBoxGroup;