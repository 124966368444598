
import { TProject } from "types/TProject";
import { create } from "zustand";

export type TProjectList = {
    projectList: Array<TProject>
    projectCollection: Map<number, TProject>;
    setProjectList: (list: Array<TProject>) => void;
    setProjectCollection: (data: Map<number, TProject>) => void;
    getProjectCollection: () => Map<number, TProject>;
    clear: () => void;
};


export const ProjectListStore = create<TProjectList>((set, get) => ({
    projectList: new Array<TProject>(),
    projectCollection: new Map<number, TProject>(),
    setProjectList: (list) => set({ projectList: list }),
    setProjectCollection: (data) => {
        set({ projectCollection: data });
        get().setProjectList(Array.from(data.values()));
    },
    getProjectCollection: () => get().projectCollection,
    clear: () => {
        get().setProjectCollection(new Map<number, TProject>());
    }
}));