import SearchInput from 'components/searchInput/SearchInput';
import { useRequest } from 'hooks/feature/useRequest';
import { useOpenLayers } from 'hooks/ol/useOpenLayers';
import { useEffect, useState } from 'react';
import { SelectedProjectStore } from 'stores/SelectedProject';
import styles from './Header.module.scss';

const Header = () => {
    const { project } = SelectedProjectStore();
    const { clearAll, initSwipe } = useOpenLayers();
    const { onClickDetail, setSelected } = useRequest();

    const [logo, setLogo] = useState<string>("");

    useEffect(() => {
        project && setLogo(project.logoUrl ?? "");
    }, [project]);

    const onLogoClick = () => {
        clearAll();
        initSwipe();
        onClickDetail(false);
        setSelected(null);
    }

    return <div className={`${styles.header} ${styles['-basic']}`}>
        {project?.logoUrl ? <img src={logo} alt="" onClick={onLogoClick} /> : <span className={styles.logo} onClick={onLogoClick} />}
        <SearchInput></SearchInput>
    </div>
}

export default Header;