
import styles from './CustomResultContainer.module.scss';
import { CustomResultContainerProps } from './CustomResultContainer.type';

const CustomResultContainer = ({ children }: CustomResultContainerProps) => {
  return (
    <div className={styles['container']}>
      {children}
    </div>
  );
};

export default CustomResultContainer;
