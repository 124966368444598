import { getAddress } from "api/Address";

export const useAddress = () => {
  const fetchAddress = async (x: number, y: number) => {
    return await getAddress(x, y);
  }

  return {
    fetchAddress
  }
}