import React from "react"
import classNames from "classnames";
import styles from "./FilterChip.module.scss";
import { TFilterChipProps } from "./FilterChip.type";
import { STATUS, TASK, TStatus, TTaskKey } from "constants/Constants";
const FilterChip = (props: TFilterChipProps) => {
    const getChipText = () => {
        return STATUS[props.chip as TStatus] ?? TASK[props.chip as TTaskKey] ?? '';
    }

    return <label className={styles.chip}>
        {getChipText()}
        <span className={classNames(styles.ico, styles.close)} onClick={() => { props.onRemove(props.chip) }}></span>
    </label>
}
export default FilterChip;