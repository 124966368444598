import { projectList } from "api/Project"
import { useEffect, useState } from "react";
import { ProjectListStore } from '../../stores/ProjectList';
import { SelectedProjectStore } from "stores/SelectedProject";
import { useRequest } from "./useRequest";
import { TProject } from "types/TProject";
import { FilterStateStore } from "stores/FilterState";
import { useQueryParam } from "hooks/route/useQueryParam";
import { PROJTECT_ID } from "constants/Constants";


export const useProject = () => {
    const { getQueryParamCollection } = useQueryParam();
    const { loadRequestListByProject, initRequestMap, clearSelectedRequest, setRequestByQuaryParam } = useRequest();
    const { project, setSelected } = SelectedProjectStore();
    const { clear: clearProjList, setProjectCollection, getProjectCollection } = ProjectListStore();
    const { initFilter } = FilterStateStore();

    useEffect(() => {
        if (!project) return;
        initFilter();
        initRequestMap();
        clearSelectedRequest();
    }, [project]);

    const getProjectIdByParam = () => {
        return getQueryParamCollection().get(PROJTECT_ID);
    }

    const setProjectByQuaryParam = (map: Map<number, TProject>) => {
        let pId = getProjectIdByParam();
        try {
            const find = map.get(pId ?? 0);
            if (!find) throw `invalid Project Id.  projectId : ${pId}`;
            setSelected(find);
            return pId;
        } catch (e) {
            console.info(e);
            pId = undefined;
        } finally {
            return pId;
        }

    }

    const fetchProjectList = async () => {
        const info = await projectList();
        clearProjList();
        const map = new Map();
        info.projectList.forEach((proj) => map.set(proj.projectId, proj));
        setProjectCollection(map);
    }

    const fetchRequestList = async (id: number) => {
        await loadRequestListByProject(id);
    }

    const setComponentByQueryParam = async () => {
        const pId = setProjectByQuaryParam(getProjectCollection());
        if (!pId) return;
        await fetchRequestList(pId);
        setRequestByQuaryParam(getQueryParamCollection());
    }

    return {
        project,
        projectId: project?.projectId,
        fetchProjectList,
        setComponentByQueryParam,
    }
}

