import React, { useEffect } from "react";
import OutsideClickHandler from 'react-outside-click-handler';
import styles from "./FilterModal.module.scss";
import { TFilterModalProps } from "./FilterModal.type";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { STATUS, TASK } from "constants/Constants";
import CheckBoxGroup from "./checkbox/CheckBoxGroup";
import { useFilter } from "hooks/feature/useFilter";
const FilterModal = (props:TFilterModalProps) => {    
    const {t} = useTranslation();    
    const {statusSize, taskSize, filterList, clear, add, remove} = useFilter();

    const onClearFilter = () => {
        clear();        
    }

    return <OutsideClickHandler onOutsideClick={props.onClickOutside}>
        <div className={styles.modal} onMouseOver={(e)=>{e.stopPropagation();}} onClick={(e)=>{e.stopPropagation();}}>
            <div className={styles.header}>
                <span className={classNames([styles['-ico'], styles['-filter']])}/>
                <span className={styles['-title']}>{t('panel.filter.modal.title')}</span>
                <span className={styles['-count']}>{filterList.length}</span>
                <button className={styles.button} onClick={onClearFilter}>{t('panel.filter.modal.clear')}</button>
            </div>
            <div className={styles.options}>
                <CheckBoxGroup checkCount={statusSize} entity={Object.entries(STATUS)} groupLabel={'Status'} line='right' add={add} remove={remove}></CheckBoxGroup>
                <CheckBoxGroup checkCount={taskSize} entity={Object.entries(TASK)} groupLabel={'Task'} add={add} remove={remove}></CheckBoxGroup>
            </div>
        </div>
    </OutsideClickHandler>
}

export default FilterModal;