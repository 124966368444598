import React, { useEffect, useRef, useState } from "react";
import styles from "./ListHeader.module.scss";
import { useTranslation } from "react-i18next";
import { TListHeader } from "./ListHeader.type";
import FilterChip from "components/chip/filterChip/FilterChip";
import { FilterStateStore } from "stores/FilterState";
import { useFilter } from "hooks/feature/useFilter";

const ListHeader = (props: TListHeader) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement | null>(null);
    const { statusSize, taskSize, filterList, remove, actions } = useFilter();
    const [chips, setChips] = useState(filterList);

    useEffect(() => {
        props.setSize(ref.current?.offsetHeight ?? 58);
    }, [chips]);

    useEffect(() => {
        setChips(filterList);
    }, [statusSize, taskSize])

    const onRemoveChip = (state: string) => {
        remove(state);
        setChips(actions.toArray());
    }

    const setSelectedFilterChip = () => {
        return chips.map((chip, idx) => {
            return <FilterChip chip={chip} key={`${chip}-${idx}`} onRemove={onRemoveChip}></FilterChip>
        })
    }

    return <div className={styles.container} ref={ref}>
        {chips.length > 0 && <div className={styles['chip-box']}>{setSelectedFilterChip()}</div>}
        <div className={styles['total-box']}>
            {t('panel.total')}
            &nbsp;
            <span className={styles.count}>{props.count ?? 0}</span>
        </div>
    </div>
}
export default ListHeader;