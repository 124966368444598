import { NONE } from "constants/Constants";
import { MenuList } from "data/MenuList";
import { create } from "zustand";

type CurrentMenu = {
    menu: string;
    actions: {
        initMenu:() => void;
        setMenu: (map: string) => void;
    }    
};


export const CurrentMenuStore = create<CurrentMenu>((set, get) => ({
    menu: NONE,
    actions: {
        initMenu: () => {
            get().actions.setMenu(MenuList[0].type);
        },
        setMenu: (menu) => set({ menu }),
    }
}));