import classNames from 'classnames';
import { CHANGE_DETECTION_POSITION, ChangeDetectionPosition } from 'constants/Constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FoldButton from '../../common/foldButton/FoldButton';
import styles from './Information.module.scss';
import { InformationProps } from './Information.type';

const Information = ({ request }: InformationProps) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(true);

    const extractTileUrl = (position: ChangeDetectionPosition) => {
        return request.requestImageDataList.find((imageData) => imageData.position === position)?.imageTileUrl;
    };

    return (
        <div className={styles['container']}>
            <div className={styles['title-area']}>
                <div className={styles['title']}>{t('panel.change_detection.information')}</div>
                <FoldButton open={isOpen} onClick={() => setOpen(!isOpen)} />
            </div>
            <div className={classNames(styles['data-area'], { [styles['folded']]: !isOpen })}>
                <div className={styles['data-box']}>
                    <div className={styles['title']}>{t('panel.change_detection.data_source')}</div>
                    <div className={styles['data-sources']}>
                        {request.requestImageDataList.length >= 2 ?
                            <>
                                <div className={styles['data-source']}>
                                    <span className={styles['direction']}>{t('panel.change_detection.left')}</span>
                                    <span className={styles['file']}>{extractTileUrl(CHANGE_DETECTION_POSITION.LEFT)}</span>
                                </div>
                                <div className={styles['data-source']}>
                                    <span className={styles['direction']}>{t('panel.change_detection.right')}</span>
                                    <span className={styles['file']}>{extractTileUrl(CHANGE_DETECTION_POSITION.RIGHT)}</span>
                                </div>
                            </> :
                            <div className={styles['data-source']}>
                                <span className={styles['file']}>{request.requestImageDataList[0].imageTileUrl}</span>
                            </div>
                        }

                    </div>
                </div>
                <div className={styles['data-box']}>
                    <div className={styles['title']}>{t('panel.change_detection.select_model')}</div>
                    <div className={styles['value']}>
                        {request.aiCore.coreName} {request.aiCore.versions.length > 0 && `(ver ${request.aiCore.versions[0].version})`}
                    </div>
                </div>
                <div className={styles['data-box']}>
                    <div className={styles['title']}>{t('panel.change_detection.output_shape')}</div>
                    <div className={styles['value']}>{request.outputShape}</div>
                </div>
            </div>
        </div>
    );
};

export default Information;
