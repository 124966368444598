import Slider from 'components/common/slider/Slider';
import { useTranslation } from 'react-i18next';
import styles from './OpacityControl.module.scss';
import { OpacityControlProps } from './OpacityControl.type';

const OpacityControl = ({ opacity, disabled, onChange }: OpacityControlProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles['container']}>
            <span className={styles['title']}>{t('opacity')}</span>
            <Slider width={180} value={opacity} onChange={onChange} disabled={disabled} />
        </div>
    );
};

export default OpacityControl;
