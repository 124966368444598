import classNames from 'classnames';
import { DATE_FORMAT } from 'constants/Constants';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from "./DatePicker.module.scss";
import { TDatePrickerProps } from "./DatePicker.type";
import "./react-datepicker.css";
const Calendar = ({ calendarDays, range, onUpdateRange, width = 240, disabled }: TDatePrickerProps) => {
    const [calendarStartDate, setCalendarStartDate] = useState<Date | null>(null);
    const [calendarEndDate, setCalendarEndDate] = useState<Date | null>(null);

    useEffect(() => {
        setCalendarStartDate(range[0]);
        setCalendarEndDate(range[1]);
    }, [range]);


    const getDayClassName = (date: Date) => {
        const day = date.getDay();
        return day === 6 ? 'saturday' : day === 0 ? 'sunday' : '';
    };
    const formatWeekDay = (name: string) => {
        return name.toUpperCase().substr(0, 3);
    };

    const onUpdateDateRange = (update: [Date | null, Date | null]) => {
        onUpdateRange && onUpdateRange(update);
    }

    const onChangeRaw = (event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        if (!event || !(event.currentTarget instanceof HTMLInputElement)) return;
        event.preventDefault();
    }

    return (
        <label style={{ width: width }} className={classNames([`${disabled ? 'calendar-disabled' : ""}`, styles.container])}>
            <i className={classNames([styles.ico, styles['-calendar']])}></i>
            <DatePicker
                disabled={disabled}
                placeholderText={`${DATE_FORMAT}~${DATE_FORMAT}`}
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    decreaseYear,
                    increaseYear,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div className="custom_header">
                        <div className={styles["button_area"]}>
                            <button className={classNames([styles['header-btn'], styles['-double-left']])} onClick={decreaseYear} disabled={prevMonthButtonDisabled}></button>
                            <button className={classNames([styles['header-btn'], styles['-left']])} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}></button>
                        </div>
                        <div className="header-date">
                            {dayjs(date).format('YYYY.MM')}
                        </div>
                        <div className={styles["button_area"]}>
                            <button className={classNames([styles['header-btn'], styles['-right']])} onClick={increaseMonth} disabled={nextMonthButtonDisabled}></button>
                            <button className={classNames([styles['header-btn'], styles['-double-right']])} onClick={increaseYear} disabled={prevMonthButtonDisabled}></button>
                        </div>
                    </div>
                )}
                onChangeRaw={onChangeRaw}
                dateFormat={'YYYY.MM.dd'}
                selectsRange={true}
                startDate={calendarStartDate!}
                endDate={calendarEndDate!}
                onChange={onUpdateDateRange}
                isClearable={true}
                dayClassName={date => getDayClassName(date)}
                formatWeekDay={formatWeekDay}
                renderDayContents={(day, date) => {
                    return <span className="day__text">{day}</span>;
                }}
            />
        </label>
    );
};

export default Calendar;
