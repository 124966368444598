import { TFilter } from "types/TFilter";
import { create } from "zustand";

type FilterState = {
    status: Set<string>;
    task: Set<string>;
    keyword: string
    filterList: Array<string>;
    statusSize: number,
    taskSize: number,
    page: number,
    size: number,
    startDate: string | null;
    endDate: string | null;
    dateRange: [Date | null, Date | null]
    actions: {
        getChip: () => Array<string>;
        getSize: (type: string) => number;
        getFilter: () => TFilter;
        get: (type: string) => Set<string>;
        addStatus: (status: string) => void;
        addTask: (task: string) => void;
        addPage: (num: number) => void;
        add: (type: string, status: string) => void;
        removeStatus: (status: string) => void;
        removeTask: (status: string) => void;
        remove: (status: string) => void;
        setKeyword: (str: string) => void;
        setStatus: (status: Set<string>) => void;
        setTask: (task: Set<string>) => void;
        setPage: (page: number) => void;
        setStartDate: (date: string | null) => void;
        setEndDate: (date: string | null) => void;
        setDateRange: (range: [Date | null, Date | null]) => void;
        toArray: () => Array<string>;
        has: (type: string, status: string) => boolean;
        clear: () => void;
    },
    initFilter: () => void;
};


export const FilterStateStore = create<FilterState>((set, get) => ({
    status: new Set(),
    task: new Set(),
    keyword: '',
    filterList: new Array<string>(),
    statusSize: 0,
    taskSize: 0,
    page: 1,
    size: 20,
    startDate: null,
    endDate: null,
    dateRange: [null, null],
    actions: {
        getChip: () => {
            return Array.from(get().status).concat(Array.from(get().task));
        },

        getSize: (type: string) => {
            return type.toLowerCase() === 'status' ? get().statusSize : get().taskSize;
        },
        getFilter: () => {
            return {
                searchKeyword: get().keyword,
                searchStartDate: get().startDate ?? '',
                searchEndDate: get().endDate ?? '',
                page: get().page,
                size: get().size,
                status: Array.from(get().status),
                task: Array.from(get().task),
            }
        },
        get: (type: String) => {
            return type.toLowerCase() === 'status' ? get().status : get().task;
        },
        addPage: (page: number) => {
            set({ page })
        },
        addStatus: (add: string) => {
            if (get().status.has(add)) return;
            get().status.add(add);
            set({ filterList: get().actions.getChip() });
            set({ statusSize: get().status.size });
        },
        addTask: (add: string) => {
            if (get().task.has(add)) return;
            get().task.add(add);
            set({ filterList: get().actions.getChip() });
            set({ taskSize: get().task.size });
        },
        add: (type: string, status: string) => {
            type.toLowerCase() === 'status' ? get().actions.addStatus(status) : get().actions.addTask(status);
        },
        removeStatus: (state) => {
            get().status.delete(state);
            set({ filterList: get().actions.getChip() });
            set({ statusSize: get().status.size });
        },
        removeTask: (state) => {
            get().task.delete(state);
            set({ filterList: get().actions.getChip() });
            set({ taskSize: get().task.size });
        },
        remove: (state) => {
            get().status.has(state) && get().actions.removeStatus(state);
            get().task.has(state) && get().actions.removeTask(state);
        },
        setKeyword: (str: string) => {
            set({ keyword: str });
        },
        setStatus: (status) => {
            set({ status });
            set({ filterList: get().actions.getChip() });
            set({ statusSize: get().status.size });
        },
        setTask: (task) => {
            set({ task });
            set({ filterList: get().actions.getChip() });
            set({ taskSize: get().task.size });
        },
        setPage: (page: number) => set({ page }),
        setStartDate: (date) => {
            set({ startDate: date })
        },
        setEndDate: (date) => {
            set({ endDate: date })
        },
        setDateRange: (range) => set({ dateRange: range }),
        toArray: () => {
            return get().filterList;
        },
        has: (type, state) => {
            return type.toLowerCase() === 'status' ? get().status.has(state) : get().task.has(state)
        },
        clear: () => {
            get().actions.setStatus(new Set());
            get().actions.setTask(new Set());
            get().filterList.length = 0;
        },
    },
    initFilter: () => {
        get().actions.clear();
        set({ startDate: null });
        set({ endDate: null });
        set({ dateRange: [null, null] });
        set({ keyword: '' });
        set({ page: 1 });
    }
}));