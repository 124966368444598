import { useMemo, useRef, useState } from 'react';
import styles from './Swipe.module.scss';
import { SwipeProps } from './Swipe.type';

const Swipe = ({ value, dateStr, onChange }: SwipeProps) => {
    const [isMousedown, setMousedown] = useState(false);
    const rangeRef = useRef<HTMLInputElement>(null);
    const computedPointerEventStyle = useMemo(() => (isMousedown ? 'auto' : 'none'), [isMousedown]);

    const handleMousedown = () => {
        setMousedown(true);
    };

    const handleMousemove = (e: React.MouseEvent) => {
        if (!isMousedown || !rangeRef.current) return;

        const rangeRect = rangeRef.current.getBoundingClientRect();
        const offsetX = e.clientX - rangeRect.left;
        const percent = Math.round((offsetX / rangeRect.width) * 100);

        onChange(percent);
    };

    const handleMouseup = () => {
        setMousedown(false);
    };

    return (
        <div className={styles['swipe-container']} onMouseMove={handleMousemove} onMouseUp={handleMouseup} style={{ pointerEvents: computedPointerEventStyle }}>
            <input type="range" className={styles['input-range']} ref={rangeRef} />
            <div className={styles['swipe-bar-wrap']} onMouseDown={handleMousedown}>
                <div className={styles['swipe-bar']} style={{ left: `${value}%` }}></div>
                <div className={styles['swipe-handle']} style={{ left: `${value}%` }}></div>

            </div>
            <div className={styles["date__wrap"]} style={{ width: `${value}%` }}>
                <div className={styles["date"]}>{dateStr && dateStr[0]}</div>
            </div>
            <div className={styles["date__wrap"]} style={{ width: `${(100 - value)}%` }}>
                <div className={styles["date"]}>{dateStr && dateStr[1]}</div>
            </div>
        </div>
    );
};

export default Swipe;
