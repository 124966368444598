import React, { useEffect, useState } from "react";
import styles from "./FilterButton.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import FilterModal from "components/popup/modal/FilterModal";

const Filter = () => {
    const { t } = useTranslation();
    const [modalOpen, setModalState] = useState(false);    
    const onClickFilterBtn = ()=> {
        setModalState(true);
    }

    const onCloseFilterModal = () => {
        setModalState(false);
    }
    return <><label className={styles.container} onClick={onClickFilterBtn}>
        <i className={classNames([styles.ico, styles.filter])}></i>        
        <button className={styles["filter-btn"]}>{t('panel.filter.button')}</button>        
    </label>
    {modalOpen && <FilterModal onClickOutside={onCloseFilterModal}></FilterModal>}
    </>
}

export default Filter;