import { create } from 'zustand';

type Member = {
    id: number;
    setId: (id: number) => void;
};

export const MemberStore = create<Member>((set) => ({
    id: -1,
    setId: (id) => set({ id }),
}));
