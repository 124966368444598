import React, { useCallback, useEffect, useState } from "react"
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './DropdownBox.module.scss';
import classNames from 'classnames';
import { TProject } from "types/TProject";
import { SelectedProjectStore } from "stores/SelectedProject";


type TDropdownProps = {
    list: Array<TProject>;
}
const DropdownBox = ({ list }: TDropdownProps) => {
    const [isOpen, setOpen] = useState(false);
    const { project, setSelected } = SelectedProjectStore();

    useEffect(() => {
        setFirstProject()
    }, [list]);

    const setFirstProject = useCallback(() => {
        list.length > 0 && setSelected(project ?? list[0]);
    }, [list])

    const handleOutsideClick = () => {
        if (isMinProjectLength()) return;
        isOpen && setOpen(false);
    }

    const isMinProjectLength = () => {
        return list.length <= 1;
    }

    const onClickDropdown = () => {
        setOpen(isMinProjectLength() ? false : !isOpen);
    }

    const onClickItem = (proj: TProject) => {
        setSelected(proj);
    }

    const initDropdownItems = () => {
        return list.map((item) => {
            return [<li className={classNames([styles.item, project?.projectId === item.projectId && styles.selected])} key={item.projectId} onClick={() => { onClickItem(item) }}>
                <span className={styles.folder}></span>
                <span className={styles.title}>{item.projectName}</span>
            </li>]
        })
    }
    return project ? <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <div className={classNames([styles.header, isOpen && styles.open])} onClick={onClickDropdown}>
            <span className={styles.folder}></span>
            <span className={styles.title}>{project.projectName}</span>
            {isMinProjectLength() || <span className={classNames([styles.arrow, styles[`-${isOpen ? 'up' : 'down'}`]])}></span>}
        </div>
        <ul className={classNames([styles.dropdown, isOpen && styles.open])} onClick={onClickDropdown}>
            {isOpen && initDropdownItems()}
        </ul>
    </OutsideClickHandler> : <></>
}

export default DropdownBox;