import { requestListBy } from "api/Request";
import { REQUEST_ID } from "constants/Constants";
import { useOpenLayers } from "hooks/ol/useOpenLayers";
import { DetailPanelStateStore } from "stores/DetailState";
import { FilterStateStore } from "stores/FilterState";
import { RequestStore } from "stores/RequestList";
import { SelectedRequestStore } from "stores/SelectedRequest";
import { TRequestContent } from "types/TRequest";


export const useRequest = () => {
    const { request, setSelected, clear } = SelectedRequestStore();
    const { requestList, getRequestCollection, setRequest, addRequestCollection, toCollection } = RequestStore();
    const { actions, page } = FilterStateStore();
    const { drawBBox, drawBaseImage, clearAll, initSwipe, initMap } = useOpenLayers();
    const { isDetail, setIsDetail } = DetailPanelStateStore();

    const loadRequestListByProject = async (id: number) => {
        if (!id) return;
        const data = await requestListBy(id, actions.getFilter());
        setRequest(data);
    };

    const setRequestByQuaryParam = (collection: Map<string, number>) => {
        const rId = collection.get(REQUEST_ID);
        try {
            const find = getRequestCollection().get(rId ?? 0);
            if (!find) throw `invalid Request ID. requestId : ${rId}`;
            setSelected(find);
            setIsDetail(true);
            drawBBox(find);
        } catch (e) {
            console.info(e);
        }
    }

    const getNextRequest = async (id: number) => {
        const currentPage = page;
        actions.addPage(currentPage + 1);
        const list = await requestListBy(id, actions.getFilter());
        list && list.content && addRequestCollection(toCollection(list.content));
        list && list.content.length === 0 && actions.addPage(currentPage);
        return list ? list.content.length > 0 : false;
    };

    const onSelectRequest = (task: TRequestContent) => {
        setIsDetail(false);
        drawBBox(task, request?.id !== task.id);
        drawBaseImage(task.requestImageDataList[0].imageTileUrl);
        setSelected(task);
    }

    const onClickDetail = (is: boolean = true) => {
        setIsDetail(is);
    }

    const initRequestMap = () => {
        clearAll();
        initSwipe();
        initMap();
    }

    return {
        selectedRequest: request,
        requestList,
        isDetail,
        request,
        setSelected,
        setRequestByQuaryParam,
        loadRequestListByProject,
        getNextRequest,
        onSelectRequest,
        onClickDetail,
        initRequestMap,
        clearSelectedRequest: clear
    }

}
