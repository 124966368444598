import { create } from 'zustand';

type DetailPanelState = {
    isDetail: boolean;
    setIsDetail: (is: boolean) => void;
};

export const DetailPanelStateStore = create<DetailPanelState>((set) => ({
    isDetail: false,
    setIsDetail: (is) => set({ isDetail: is }),
}));
