import { detailV3 } from 'api/Request';
import { getDownloadGeoJSON } from 'api/Result';
import { downloadFile } from 'utils/Utils';

export const useResult = () => {
    const getResult = async (requestId: number) => {
        // return await detail(requestId);
        return await detailV3(requestId);
    };

    const downloadGeoJSON = async (requestId: number) => {
        try {
            const data = await getDownloadGeoJSON(requestId);
            downloadFile(data, `${requestId}.geojson`);
        } catch (e) {}
    };

    return {
        getResult,
        downloadGeoJSON,
    };
};
