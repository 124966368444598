

import styles from "./Loading.module.scss";

export default function Loading() {
  return (
    <>
      <div className={styles["loading__container"]}>
        <div className={styles["box"]}>
          <div className={styles["animate-logo"]}>
            {/* <div className={styles["fixed"]}></div>
            <div className={styles["circle"]}></div> */}
            <div className={styles["logo-1"]}></div>
            <div className={styles["logo-2"]}></div>
            <div className={styles["logo-3"]}></div>
            <div className={styles["logo-4"]}></div>
            <div className={styles["logo-5"]}></div>
            <div className={styles["logo-6"]}></div>
          </div>
          <div className={styles["animate-text"]}>Please wait a moment.</div>
        </div>
      </div>
    </>
  )
}