export const AUTHENTICATION = {
    refresh_token: '/auth/refresh',
    logout: '/auth/logout',
};

export const END_POINT = {
    PROJECT_LIST: '/assigned-project',
    REQUEST_LIST: '/request/project',
    DETAIL: '/request',
    MEMBER: '/member',
    DOWNLOAD_GEOJSON: '/geojson-download'
};

export const END_POINT_KAKAO = {
    ADDRESS: '/v2/local/geo/coord2address.json?input_coord=WGS84&x=:x&y=:y'
}