
import { END_POINT } from "constants/Api";
import { ProjectList } from "types/TProjectList";
import { getUrl } from "utils/Utils";
import api from "./Instance";

export const projectList = async()=> {    
    
    const response =  await api.get<ProjectList>(getUrl(END_POINT.PROJECT_LIST));
    return response;
}
