export type Tokens = {
    accessToken: string;
    refreshToken: string;
};

export type AuthenticationResult = {
    tokens: Tokens;
    memberId: number;
};

export const USER_ROLE = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    PROJECT_ADMIN: 'PROJECT_ADMIN',
    USER: 'USER'
} as const;

export type UserRoles =  (typeof USER_ROLE)[keyof typeof USER_ROLE]; 